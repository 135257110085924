/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PersonalizedJogosultsag } from './personalizedJogosultsag';

export interface MegbizoJogosultsagBundle { 
    beallithatoAuthorityList?: Array<MegbizoJogosultsagBundle.BeallithatoAuthorityListEnum>;
    elerhetoAuthorityList?: Array<MegbizoJogosultsagBundle.ElerhetoAuthorityListEnum>;
    jogosultsagList?: Array<PersonalizedJogosultsag>;
    kereshetFelhasznalot?: boolean;
}
export namespace MegbizoJogosultsagBundle {
    export type BeallithatoAuthorityListEnum = 'ONLINE_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_GODMODE' | 'SUPER_ADMIN' | 'ELSZAMOLOHAZ' | 'UGYINTEZOI_HORGASZREGISZTRACIO' | 'UGYINTEZOI_JOVAHAGYAS' | 'UGYINTEZOI_FIZETESMGMT' | 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' | 'UGYFELSZOLGALATI_JOVAHAGYAS' | 'UGYFELSZOLGALATI_FIZETESMGMT' | 'ADOKARTYA_UTOLAGOS_ELLENORZES' | 'UGYFELSZOLGALATI_JUTALEK' | 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_TORZSADATMGMT' | 'UGYFELSZOLGALATI_USERMGMT' | 'UGYFELSZOLGALATI_ELTILTASMGMT' | 'ELTILTAS_FELTOLTES' | 'ERTEKESITESI_RIPORT' | 'FOGASNYILVATTARTAS_RIPORT' | 'FOGASINAPLOLEADAS_RIPORT' | 'EGYESULETITAGSAG_RIPORT' | 'TERULETIJEGY_RIPORT' | 'VIZSGABIZOTTSAG_RIPORT' | 'FORGALOM_RIPORT' | 'UZENET_MGMNT' | 'VIZTERULET_MGMT' | 'ELLENOR' | 'ELLENOR_MGMT' | 'ALLAMIJEGY_ARLISTA_MGMT' | 'ALLAMI_JEGY_ERTEKESITES' | 'FOGASI_NAPLO_DISZTRIBUCIO' | 'TAGDIJ_ARLISTA_MGMT' | 'TERULETI_JEGY_ERTEKESITES' | 'TERULETI_JEGY_TERMEK_MGMT' | 'VIZSGABIZOTTSAG_MGMT' | 'VIZSGABIZTOS' | 'SZERZODES_KOTES' | 'KESZLETEZO_MGMT' | 'FELADATSOR_MGMT' | 'HORGASZKARTYA_IGENYLES_MGMT' | 'DOKUMENTUMTAR_MGMT' | 'MAHOR_ELOFIZETES_MGMT' | 'VERSENY_MGMT' | 'PSYS_ADMIN' | 'TECHU' | 'SZOLGALTATASMGMT' | 'SZOLGALTATAS_FELUGYELO' | 'PUBLIKUS_BEJEGYZES_FELTOLTES' | 'BEJEGYZES_MGMT' | 'ALTALANOS_RIPORT' | 'ALTALANOS_RIPORT_MGMT' | 'GATHASZNALATI_ENGEDELY_MGMT' | 'GATHASZNALATI_ENGEDELY_ERTEKESITES' | 'GATHASZNALATI_ENGEDELY_RIPORT' | 'BANNER_MGMT' | 'HORGASZVIZSGA_IGAZOLAS_FELULVIZSGALAT' | 'HORGASZVIZSGA_IGAZOLAS_FELULBIRALAT' | 'UGYFELSZOLGALATI_SZERVEZET_LETREHOZAS' | 'UGYFELSZOLGALATI_GONDVISELO_MODOSITAS' | 'UGYFELSZOLGALATI_INTEGRACIOS_LEKERDEZES';
    export const BeallithatoAuthorityListEnum = {
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZREGISZTRACIO' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIGODMODE: 'UGYFELSZOLGALATI_GODMODE' as BeallithatoAuthorityListEnum,
        SUPERADMIN: 'SUPER_ADMIN' as BeallithatoAuthorityListEnum,
        ELSZAMOLOHAZ: 'ELSZAMOLOHAZ' as BeallithatoAuthorityListEnum,
        UGYINTEZOIHORGASZREGISZTRACIO: 'UGYINTEZOI_HORGASZREGISZTRACIO' as BeallithatoAuthorityListEnum,
        UGYINTEZOIJOVAHAGYAS: 'UGYINTEZOI_JOVAHAGYAS' as BeallithatoAuthorityListEnum,
        UGYINTEZOIFIZETESMGMT: 'UGYINTEZOI_FIZETESMGMT' as BeallithatoAuthorityListEnum,
        SZERVEZETIJOGOSULTSAGBEALLITAS: 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIJOVAHAGYAS: 'UGYFELSZOLGALATI_JOVAHAGYAS' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIFIZETESMGMT: 'UGYFELSZOLGALATI_FIZETESMGMT' as BeallithatoAuthorityListEnum,
        ADOKARTYAUTOLAGOSELLENORZES: 'ADOKARTYA_UTOLAGOS_ELLENORZES' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIJUTALEK: 'UGYFELSZOLGALATI_JUTALEK' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIHORGASZREGISZTRACIO: 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATITORZSADATMGMT: 'UGYFELSZOLGALATI_TORZSADATMGMT' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIUSERMGMT: 'UGYFELSZOLGALATI_USERMGMT' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIELTILTASMGMT: 'UGYFELSZOLGALATI_ELTILTASMGMT' as BeallithatoAuthorityListEnum,
        ELTILTASFELTOLTES: 'ELTILTAS_FELTOLTES' as BeallithatoAuthorityListEnum,
        ERTEKESITESIRIPORT: 'ERTEKESITESI_RIPORT' as BeallithatoAuthorityListEnum,
        FOGASNYILVATTARTASRIPORT: 'FOGASNYILVATTARTAS_RIPORT' as BeallithatoAuthorityListEnum,
        FOGASINAPLOLEADASRIPORT: 'FOGASINAPLOLEADAS_RIPORT' as BeallithatoAuthorityListEnum,
        EGYESULETITAGSAGRIPORT: 'EGYESULETITAGSAG_RIPORT' as BeallithatoAuthorityListEnum,
        TERULETIJEGYRIPORT: 'TERULETIJEGY_RIPORT' as BeallithatoAuthorityListEnum,
        VIZSGABIZOTTSAGRIPORT: 'VIZSGABIZOTTSAG_RIPORT' as BeallithatoAuthorityListEnum,
        FORGALOMRIPORT: 'FORGALOM_RIPORT' as BeallithatoAuthorityListEnum,
        UZENETMGMNT: 'UZENET_MGMNT' as BeallithatoAuthorityListEnum,
        VIZTERULETMGMT: 'VIZTERULET_MGMT' as BeallithatoAuthorityListEnum,
        ELLENOR: 'ELLENOR' as BeallithatoAuthorityListEnum,
        ELLENORMGMT: 'ELLENOR_MGMT' as BeallithatoAuthorityListEnum,
        ALLAMIJEGYARLISTAMGMT: 'ALLAMIJEGY_ARLISTA_MGMT' as BeallithatoAuthorityListEnum,
        ALLAMIJEGYERTEKESITES: 'ALLAMI_JEGY_ERTEKESITES' as BeallithatoAuthorityListEnum,
        FOGASINAPLODISZTRIBUCIO: 'FOGASI_NAPLO_DISZTRIBUCIO' as BeallithatoAuthorityListEnum,
        TAGDIJARLISTAMGMT: 'TAGDIJ_ARLISTA_MGMT' as BeallithatoAuthorityListEnum,
        TERULETIJEGYERTEKESITES: 'TERULETI_JEGY_ERTEKESITES' as BeallithatoAuthorityListEnum,
        TERULETIJEGYTERMEKMGMT: 'TERULETI_JEGY_TERMEK_MGMT' as BeallithatoAuthorityListEnum,
        VIZSGABIZOTTSAGMGMT: 'VIZSGABIZOTTSAG_MGMT' as BeallithatoAuthorityListEnum,
        VIZSGABIZTOS: 'VIZSGABIZTOS' as BeallithatoAuthorityListEnum,
        SZERZODESKOTES: 'SZERZODES_KOTES' as BeallithatoAuthorityListEnum,
        KESZLETEZOMGMT: 'KESZLETEZO_MGMT' as BeallithatoAuthorityListEnum,
        FELADATSORMGMT: 'FELADATSOR_MGMT' as BeallithatoAuthorityListEnum,
        HORGASZKARTYAIGENYLESMGMT: 'HORGASZKARTYA_IGENYLES_MGMT' as BeallithatoAuthorityListEnum,
        DOKUMENTUMTARMGMT: 'DOKUMENTUMTAR_MGMT' as BeallithatoAuthorityListEnum,
        MAHORELOFIZETESMGMT: 'MAHOR_ELOFIZETES_MGMT' as BeallithatoAuthorityListEnum,
        VERSENYMGMT: 'VERSENY_MGMT' as BeallithatoAuthorityListEnum,
        PSYSADMIN: 'PSYS_ADMIN' as BeallithatoAuthorityListEnum,
        TECHU: 'TECHU' as BeallithatoAuthorityListEnum,
        SZOLGALTATASMGMT: 'SZOLGALTATASMGMT' as BeallithatoAuthorityListEnum,
        SZOLGALTATASFELUGYELO: 'SZOLGALTATAS_FELUGYELO' as BeallithatoAuthorityListEnum,
        PUBLIKUSBEJEGYZESFELTOLTES: 'PUBLIKUS_BEJEGYZES_FELTOLTES' as BeallithatoAuthorityListEnum,
        BEJEGYZESMGMT: 'BEJEGYZES_MGMT' as BeallithatoAuthorityListEnum,
        ALTALANOSRIPORT: 'ALTALANOS_RIPORT' as BeallithatoAuthorityListEnum,
        ALTALANOSRIPORTMGMT: 'ALTALANOS_RIPORT_MGMT' as BeallithatoAuthorityListEnum,
        GATHASZNALATIENGEDELYMGMT: 'GATHASZNALATI_ENGEDELY_MGMT' as BeallithatoAuthorityListEnum,
        GATHASZNALATIENGEDELYERTEKESITES: 'GATHASZNALATI_ENGEDELY_ERTEKESITES' as BeallithatoAuthorityListEnum,
        GATHASZNALATIENGEDELYRIPORT: 'GATHASZNALATI_ENGEDELY_RIPORT' as BeallithatoAuthorityListEnum,
        BANNERMGMT: 'BANNER_MGMT' as BeallithatoAuthorityListEnum,
        HORGASZVIZSGAIGAZOLASFELULVIZSGALAT: 'HORGASZVIZSGA_IGAZOLAS_FELULVIZSGALAT' as BeallithatoAuthorityListEnum,
        HORGASZVIZSGAIGAZOLASFELULBIRALAT: 'HORGASZVIZSGA_IGAZOLAS_FELULBIRALAT' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATISZERVEZETLETREHOZAS: 'UGYFELSZOLGALATI_SZERVEZET_LETREHOZAS' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIGONDVISELOMODOSITAS: 'UGYFELSZOLGALATI_GONDVISELO_MODOSITAS' as BeallithatoAuthorityListEnum,
        UGYFELSZOLGALATIINTEGRACIOSLEKERDEZES: 'UGYFELSZOLGALATI_INTEGRACIOS_LEKERDEZES' as BeallithatoAuthorityListEnum
    };
    export type ElerhetoAuthorityListEnum = 'ONLINE_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_GODMODE' | 'SUPER_ADMIN' | 'ELSZAMOLOHAZ' | 'UGYINTEZOI_HORGASZREGISZTRACIO' | 'UGYINTEZOI_JOVAHAGYAS' | 'UGYINTEZOI_FIZETESMGMT' | 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' | 'UGYFELSZOLGALATI_JOVAHAGYAS' | 'UGYFELSZOLGALATI_FIZETESMGMT' | 'ADOKARTYA_UTOLAGOS_ELLENORZES' | 'UGYFELSZOLGALATI_JUTALEK' | 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_TORZSADATMGMT' | 'UGYFELSZOLGALATI_USERMGMT' | 'UGYFELSZOLGALATI_ELTILTASMGMT' | 'ELTILTAS_FELTOLTES' | 'ERTEKESITESI_RIPORT' | 'FOGASNYILVATTARTAS_RIPORT' | 'FOGASINAPLOLEADAS_RIPORT' | 'EGYESULETITAGSAG_RIPORT' | 'TERULETIJEGY_RIPORT' | 'VIZSGABIZOTTSAG_RIPORT' | 'FORGALOM_RIPORT' | 'UZENET_MGMNT' | 'VIZTERULET_MGMT' | 'ELLENOR' | 'ELLENOR_MGMT' | 'ALLAMIJEGY_ARLISTA_MGMT' | 'ALLAMI_JEGY_ERTEKESITES' | 'FOGASI_NAPLO_DISZTRIBUCIO' | 'TAGDIJ_ARLISTA_MGMT' | 'TERULETI_JEGY_ERTEKESITES' | 'TERULETI_JEGY_TERMEK_MGMT' | 'VIZSGABIZOTTSAG_MGMT' | 'VIZSGABIZTOS' | 'SZERZODES_KOTES' | 'KESZLETEZO_MGMT' | 'FELADATSOR_MGMT' | 'HORGASZKARTYA_IGENYLES_MGMT' | 'DOKUMENTUMTAR_MGMT' | 'MAHOR_ELOFIZETES_MGMT' | 'VERSENY_MGMT' | 'PSYS_ADMIN' | 'TECHU' | 'SZOLGALTATASMGMT' | 'SZOLGALTATAS_FELUGYELO' | 'PUBLIKUS_BEJEGYZES_FELTOLTES' | 'BEJEGYZES_MGMT' | 'ALTALANOS_RIPORT' | 'ALTALANOS_RIPORT_MGMT' | 'GATHASZNALATI_ENGEDELY_MGMT' | 'GATHASZNALATI_ENGEDELY_ERTEKESITES' | 'GATHASZNALATI_ENGEDELY_RIPORT' | 'BANNER_MGMT' | 'HORGASZVIZSGA_IGAZOLAS_FELULVIZSGALAT' | 'HORGASZVIZSGA_IGAZOLAS_FELULBIRALAT' | 'UGYFELSZOLGALATI_SZERVEZET_LETREHOZAS' | 'UGYFELSZOLGALATI_GONDVISELO_MODOSITAS' | 'UGYFELSZOLGALATI_INTEGRACIOS_LEKERDEZES';
    export const ElerhetoAuthorityListEnum = {
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZREGISZTRACIO' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIGODMODE: 'UGYFELSZOLGALATI_GODMODE' as ElerhetoAuthorityListEnum,
        SUPERADMIN: 'SUPER_ADMIN' as ElerhetoAuthorityListEnum,
        ELSZAMOLOHAZ: 'ELSZAMOLOHAZ' as ElerhetoAuthorityListEnum,
        UGYINTEZOIHORGASZREGISZTRACIO: 'UGYINTEZOI_HORGASZREGISZTRACIO' as ElerhetoAuthorityListEnum,
        UGYINTEZOIJOVAHAGYAS: 'UGYINTEZOI_JOVAHAGYAS' as ElerhetoAuthorityListEnum,
        UGYINTEZOIFIZETESMGMT: 'UGYINTEZOI_FIZETESMGMT' as ElerhetoAuthorityListEnum,
        SZERVEZETIJOGOSULTSAGBEALLITAS: 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIJOVAHAGYAS: 'UGYFELSZOLGALATI_JOVAHAGYAS' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIFIZETESMGMT: 'UGYFELSZOLGALATI_FIZETESMGMT' as ElerhetoAuthorityListEnum,
        ADOKARTYAUTOLAGOSELLENORZES: 'ADOKARTYA_UTOLAGOS_ELLENORZES' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIJUTALEK: 'UGYFELSZOLGALATI_JUTALEK' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIHORGASZREGISZTRACIO: 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATITORZSADATMGMT: 'UGYFELSZOLGALATI_TORZSADATMGMT' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIUSERMGMT: 'UGYFELSZOLGALATI_USERMGMT' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIELTILTASMGMT: 'UGYFELSZOLGALATI_ELTILTASMGMT' as ElerhetoAuthorityListEnum,
        ELTILTASFELTOLTES: 'ELTILTAS_FELTOLTES' as ElerhetoAuthorityListEnum,
        ERTEKESITESIRIPORT: 'ERTEKESITESI_RIPORT' as ElerhetoAuthorityListEnum,
        FOGASNYILVATTARTASRIPORT: 'FOGASNYILVATTARTAS_RIPORT' as ElerhetoAuthorityListEnum,
        FOGASINAPLOLEADASRIPORT: 'FOGASINAPLOLEADAS_RIPORT' as ElerhetoAuthorityListEnum,
        EGYESULETITAGSAGRIPORT: 'EGYESULETITAGSAG_RIPORT' as ElerhetoAuthorityListEnum,
        TERULETIJEGYRIPORT: 'TERULETIJEGY_RIPORT' as ElerhetoAuthorityListEnum,
        VIZSGABIZOTTSAGRIPORT: 'VIZSGABIZOTTSAG_RIPORT' as ElerhetoAuthorityListEnum,
        FORGALOMRIPORT: 'FORGALOM_RIPORT' as ElerhetoAuthorityListEnum,
        UZENETMGMNT: 'UZENET_MGMNT' as ElerhetoAuthorityListEnum,
        VIZTERULETMGMT: 'VIZTERULET_MGMT' as ElerhetoAuthorityListEnum,
        ELLENOR: 'ELLENOR' as ElerhetoAuthorityListEnum,
        ELLENORMGMT: 'ELLENOR_MGMT' as ElerhetoAuthorityListEnum,
        ALLAMIJEGYARLISTAMGMT: 'ALLAMIJEGY_ARLISTA_MGMT' as ElerhetoAuthorityListEnum,
        ALLAMIJEGYERTEKESITES: 'ALLAMI_JEGY_ERTEKESITES' as ElerhetoAuthorityListEnum,
        FOGASINAPLODISZTRIBUCIO: 'FOGASI_NAPLO_DISZTRIBUCIO' as ElerhetoAuthorityListEnum,
        TAGDIJARLISTAMGMT: 'TAGDIJ_ARLISTA_MGMT' as ElerhetoAuthorityListEnum,
        TERULETIJEGYERTEKESITES: 'TERULETI_JEGY_ERTEKESITES' as ElerhetoAuthorityListEnum,
        TERULETIJEGYTERMEKMGMT: 'TERULETI_JEGY_TERMEK_MGMT' as ElerhetoAuthorityListEnum,
        VIZSGABIZOTTSAGMGMT: 'VIZSGABIZOTTSAG_MGMT' as ElerhetoAuthorityListEnum,
        VIZSGABIZTOS: 'VIZSGABIZTOS' as ElerhetoAuthorityListEnum,
        SZERZODESKOTES: 'SZERZODES_KOTES' as ElerhetoAuthorityListEnum,
        KESZLETEZOMGMT: 'KESZLETEZO_MGMT' as ElerhetoAuthorityListEnum,
        FELADATSORMGMT: 'FELADATSOR_MGMT' as ElerhetoAuthorityListEnum,
        HORGASZKARTYAIGENYLESMGMT: 'HORGASZKARTYA_IGENYLES_MGMT' as ElerhetoAuthorityListEnum,
        DOKUMENTUMTARMGMT: 'DOKUMENTUMTAR_MGMT' as ElerhetoAuthorityListEnum,
        MAHORELOFIZETESMGMT: 'MAHOR_ELOFIZETES_MGMT' as ElerhetoAuthorityListEnum,
        VERSENYMGMT: 'VERSENY_MGMT' as ElerhetoAuthorityListEnum,
        PSYSADMIN: 'PSYS_ADMIN' as ElerhetoAuthorityListEnum,
        TECHU: 'TECHU' as ElerhetoAuthorityListEnum,
        SZOLGALTATASMGMT: 'SZOLGALTATASMGMT' as ElerhetoAuthorityListEnum,
        SZOLGALTATASFELUGYELO: 'SZOLGALTATAS_FELUGYELO' as ElerhetoAuthorityListEnum,
        PUBLIKUSBEJEGYZESFELTOLTES: 'PUBLIKUS_BEJEGYZES_FELTOLTES' as ElerhetoAuthorityListEnum,
        BEJEGYZESMGMT: 'BEJEGYZES_MGMT' as ElerhetoAuthorityListEnum,
        ALTALANOSRIPORT: 'ALTALANOS_RIPORT' as ElerhetoAuthorityListEnum,
        ALTALANOSRIPORTMGMT: 'ALTALANOS_RIPORT_MGMT' as ElerhetoAuthorityListEnum,
        GATHASZNALATIENGEDELYMGMT: 'GATHASZNALATI_ENGEDELY_MGMT' as ElerhetoAuthorityListEnum,
        GATHASZNALATIENGEDELYERTEKESITES: 'GATHASZNALATI_ENGEDELY_ERTEKESITES' as ElerhetoAuthorityListEnum,
        GATHASZNALATIENGEDELYRIPORT: 'GATHASZNALATI_ENGEDELY_RIPORT' as ElerhetoAuthorityListEnum,
        BANNERMGMT: 'BANNER_MGMT' as ElerhetoAuthorityListEnum,
        HORGASZVIZSGAIGAZOLASFELULVIZSGALAT: 'HORGASZVIZSGA_IGAZOLAS_FELULVIZSGALAT' as ElerhetoAuthorityListEnum,
        HORGASZVIZSGAIGAZOLASFELULBIRALAT: 'HORGASZVIZSGA_IGAZOLAS_FELULBIRALAT' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATISZERVEZETLETREHOZAS: 'UGYFELSZOLGALATI_SZERVEZET_LETREHOZAS' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIGONDVISELOMODOSITAS: 'UGYFELSZOLGALATI_GONDVISELO_MODOSITAS' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIINTEGRACIOSLEKERDEZES: 'UGYFELSZOLGALATI_INTEGRACIOS_LEKERDEZES' as ElerhetoAuthorityListEnum
    };
}