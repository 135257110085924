import {Pipe, PipeTransform} from '@angular/core';
import {TeruletiKiegeszitoJegy} from 'src/api';

@Pipe({
    name: 'kiegeszitojegytipus',
    standalone: false
})
export class KiegeszitoJegyTipusEnumPipe implements PipeTransform {
  transform(value: TeruletiKiegeszitoJegy.TipusEnum, args?: any): any {
    switch (value) {
      case TeruletiKiegeszitoJegy.TipusEnum.BOJLIS: return 'Bojlis'
      case TeruletiKiegeszitoJegy.TipusEnum.BEHUZOS: return 'Behúzós'
      case TeruletiKiegeszitoJegy.TipusEnum.CSONAKHASZNALATI: return 'Csónak/lajtstromszámos vízijármű használat'
      case TeruletiKiegeszitoJegy.TipusEnum.EJSZAKAIHORGASZATI: return 'Éjszakai horgászat'
      case TeruletiKiegeszitoJegy.TipusEnum.HAROMBOTOS: return 'Hárombotos'
      case TeruletiKiegeszitoJegy.TipusEnum.HELYFOGLALASOS: return 'Helyfoglalásos'
      case TeruletiKiegeszitoJegy.TipusEnum.SPECIALISVIZTERULETI: return 'Speciális vízterület'
      case TeruletiKiegeszitoJegy.TipusEnum.TOBBLETHALELVITELI: return 'Többlet halelvitel'
      case TeruletiKiegeszitoJegy.TipusEnum.VERSENYRESZVETELI: return 'Versenyrészvétel'
      default: return value;
    }
  }
}
