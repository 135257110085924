import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { EllenorzesiTevekenysegMinositesRequest } from 'src/api';
import { DatabaseService } from 'src/app/services/database.service';
import { SelectedEllenorzesService } from 'src/app/services/selected-ellenorzes.service';
import { SzabalysertesDialogData } from './szabalysertes-dialog-data';

@Component({
    selector: 'app-szabalysertes-dialog',
    templateUrl: './szabalysertes-dialog.component.html',
    styleUrls: ['./szabalysertes-dialog.component.scss'],
    standalone: false
})
export class SzabalysertesDialogComponent implements OnInit {

  data: SzabalysertesDialogData;
  ejegyInaktivalas = false;
  enaploInaktivalas = false;
  fogasiNaploVisszatartas = false;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private alertController: AlertController,
    private databaseService: DatabaseService,
    private selectedEllenorzesService: SelectedEllenorzesService
  ) {
   }

  ngOnInit() {
  }

  changeEnaplo() {
    if (this.data.hasEnaplo) {
      this.enaploInaktivalas = !this.enaploInaktivalas
    }
  }

  changeEjegy() {
    this.ejegyInaktivalas = !this.ejegyInaktivalas
  }

  async szabalysertesClick() {
    const alert = await this.alertController.create({
      header: 'Biztosan menti az ellenőrzés eredményét?',
      message: `Mentés után az ellenőrzés már nem módosítható`,
      buttons: [
        { text: 'Mentés', handler: () => this.minositesSzabalysertes() },
        { text: 'Mégsem' }]
    });
    await alert.present();
  }

  async minositesSzabalysertes() {
    const elReq: EllenorzesiTevekenysegMinositesRequest = {
      uuid: this.selectedEllenorzesService.get().getUuid(),
      ellenorzesiTevekenysegId: this.data.ellenorzesiTevekenysegId,
      ejegyInaktivalas: this.ejegyInaktivalas,
      enaploInaktivalas: this.enaploInaktivalas,
      fogasiNaploVisszatartas: this.fogasiNaploVisszatartas,
      ellenorzesiTevekenysegMinosites: EllenorzesiTevekenysegMinositesRequest.EllenorzesiTevekenysegMinositesEnum.SZABALYSERTES
    };
    await this.databaseService.insertEllenorzesMinositesRequest(elReq);
    await this.modalController.dismiss();
    this.navController.back();
  }

  cancel() {
    this.modalController.dismiss();
  }
}
