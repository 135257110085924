import { Injectable } from '@angular/core';
import {SzemelyTeruletiEltiltas, TeruletiJegy, TeruletiKiegeszitoJegy} from "../../../api";

@Injectable({
  providedIn: 'root'
})
export class TeruletiJegyHelperService {

  SUCCESS = [
    TeruletiJegy.StatuszEnum.ERVENYES,
    TeruletiJegy.StatuszEnum.MEGVASAROLT
  ];
  DANGER = [
    TeruletiJegy.StatuszEnum.VISSZAVONT,
    TeruletiJegy.StatuszEnum.LEJART,
    TeruletiJegy.StatuszEnum.SZTORNOZOTT,
    TeruletiJegy.StatuszEnum.RKSZTORNOZOTT
  ]

  constructor() { }

  private getJegyColor(statusz: TeruletiJegy.StatuszEnum): string {
    if (this.SUCCESS.includes(statusz)) {
      return "success";
    } else if (this.DANGER.includes(statusz)) {
      return 'danger';
    } else {
      return 'warning'
    }
  }

  getKiegeszitoColor(teruletiKiegeszitoJegy: TeruletiKiegeszitoJegy): string {
    return this.getJegyColor(teruletiKiegeszitoJegy.statusz)
  }

  getColor(teruletiJegy: TeruletiJegy): string {
    return this.getJegyColor(teruletiJegy.statusz)
  }

  getEltiltasColor(eltiltas: SzemelyTeruletiEltiltas): string {
    return eltiltas.aktiv ? 'danger' : 'success';
  }
}
