<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      Előzmények
    </ion-title>
  </ion-toolbar>
  <ion-fab vertical="top" horizontal="end" slot="fixed" *ngIf="authenticationService.isOfflineMukodes()">
    <ion-fab-button color="warning">
      <ion-text color="danger">
        Offline
      </ion-text>
    </ion-fab-button>
  </ion-fab>
</ion-header>

<ion-content>
  <ion-list class="margin-top">
    <ion-item lines="none">
      <ion-label position="floating">Keresendő kifejezés (max 20 sor találat)</ion-label>
      <ion-input [(ngModel)]="searchText"></ion-input>
    </ion-item>
  </ion-list>
  <ion-button expand="full" (click)="doFilter()">Listázás</ion-button>
  <div *ngFor="let ellenorzes of filteredEllenorzesList">
    <app-ellenorzes-list-item [ellenorzes]="ellenorzes"></app-ellenorzes-list-item>
  </div>
  <ion-item *ngIf="filteredEllenorzesList.length === 0">
    <ion-label>Nincs megjeleníthető elem.</ion-label>
  </ion-item>
</ion-content>
