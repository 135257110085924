<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      Vízterület kiválasztása
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-card-subtitle>
        <h5><span *ngIf="user && user.selectedVizterulet">{{user.selectedVizterulet.nev}}</span><span
            *ngIf="user && !user.selectedVizterulet">Nincs kiválasztott vízterület</span>
        </h5>
      </ion-card-subtitle>
    </ion-card-header>
  </ion-card>
  <ion-item-divider></ion-item-divider>
  <ion-list>
    <ion-list-header>
      <h3>Vízterületek</h3>
    </ion-list-header>
    <ion-toolbar>
      <ion-searchbar debounce="500" showCancelButton="focus" (ionInput)="getItems($event)"></ion-searchbar>
    </ion-toolbar>
    <ion-item *ngFor="let vizterulet of vizteruletList">
      <ion-button expand="block" (click)="selectVizter(vizterulet)">{{vizterulet.nev}} - {{vizterulet.vizterkod}}</ion-button>
    </ion-item>
  </ion-list>
</ion-content>
