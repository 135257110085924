import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { inject, NgModule, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ApiModule } from 'src/api/api.module';
import { VizteruletPipe } from 'src/app/pipes/vizterulet.pipe';
import { AuthInterceptor } from 'src/app/services/auth.interceptor';
import { StorageService } from 'src/app/services/storage.service';
import { apiModuleConfiguration } from 'src/config/ApiModuleConfiguration';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ErrorInterceptor } from './services/error-interceptor';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    ApiModule.forRoot(apiModuleConfiguration)
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideAppInitializer(() => {
      const initializerFn = (service: StorageService) => service.init();
      return initializerFn(inject(StorageService));
    }),
    provideHttpClient(withInterceptorsFromDi()),
    VizteruletPipe
  ]
})
export class AppModule {
}
