import { Injectable } from '@angular/core';
import { Mutex } from 'async-mutex';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { BatchEllenorLokacioRogzitesRequest, BatchEllenorzesRequest, BatchHorgaszAdatLekerdezesRequest, EllenorAppControllerService, EllenorLocationHistoryControllerService, EllenorLokacioRogzitesRequest, EllenorzesiTevekenysegFilterRequest, EllenorzesiTevekenysegMinositesRequest, EllenorzesiTevekenysegReply, EllenorzesiTevekenysegReplyRow, HorgaszAdatReply, HorgaszAdatRequest, SssUser } from 'src/api';
import { StorageService } from 'src/app/services/storage.service';
import { DatabaseEllenorzes } from '../objects/databaseEllenorzes';
import { DatabaseEllenorzesRecord } from '../objects/databaseEllenorzesRecord';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  ellenorzesek: Observable<Array<DatabaseEllenorzes>>
  private ellenorzesekSubject = new BehaviorSubject<Array<DatabaseEllenorzes>>([]);

  private lokacioMutex = new Mutex();
  private LOKACIO_KEY = "LOKACIO_KEY";

  private ellenorzesMutex = new Mutex();
  private ELLENORZES_KEY = "ELLENORZES_KEY";

  constructor(
    private storageService: StorageService,
    private ellenorLocationHistoryControllerService: EllenorLocationHistoryControllerService,
    private ellenorAppControllerService: EllenorAppControllerService
  ) {
    this.ellenorzesek = this.ellenorzesekSubject.asObservable();
  }

  async insertLokacio(lokacio: EllenorLokacioRogzitesRequest) {
    await this.lokacioMutex.runExclusive(async () => {
      const storedContent: Array<EllenorLokacioRogzitesRequest> = await this.storageService.get(this.LOKACIO_KEY) ?? [];
      storedContent.push(lokacio);
      await this.storageService.set(this.LOKACIO_KEY, storedContent);
    });
  }

  async syncLokaciok(): Promise<void> {
    await this.lokacioMutex.runExclusive(async () => {
      const storedContent: Array<EllenorLokacioRogzitesRequest> = await this.storageService.get(this.LOKACIO_KEY) ?? [];
      if (storedContent.length > 0) {
        try {
          const request: BatchEllenorLokacioRogzitesRequest = { requestList: storedContent }
          await firstValueFrom(this.ellenorLocationHistoryControllerService.batchLokacioRogzites(request))
          await this.storageService.set(this.LOKACIO_KEY, [])
        } catch (error) {
          console.error(error);
        }
      }
    })
  }

  async initEllenorzesList(user: SssUser) {
    await this.syncEllenorzesek()
    await this.ellenorzesMutex.runExclusive(async () => {
      const filter: EllenorzesiTevekenysegFilterRequest = {
        ellenorSzemelyId: user.trusteeId,
        szervezetId: user.entrustingId,
        vizteruletIdList: []
      };
      const reply: EllenorzesiTevekenysegReply = await firstValueFrom(this.ellenorAppControllerService.tevekenysegListazas(filter));
      const ellenorzesList: Array<DatabaseEllenorzes> = reply.tevekenyseg.map(ellenorzes => DatabaseEllenorzes.fromEllenorzes(ellenorzes))
      await this.storageService.set(this.ELLENORZES_KEY, ellenorzesList.map(ellenorzes => ellenorzes.data));
      this.ellenorzesekSubject.next(ellenorzesList);
    });
  }

  async insertEllenorzesRequest(horgaszAdatRequest: HorgaszAdatRequest, horgaszAdatReply?: HorgaszAdatReply) {
    return await this.ellenorzesMutex.runExclusive(async () => {
      const storedContent: Array<DatabaseEllenorzesRecord> = await this.storageService.get(this.ELLENORZES_KEY) ?? [];
      const ellenorzesList = storedContent.map(sEllenorzes => new DatabaseEllenorzes(sEllenorzes))
      let ellenorzes: DatabaseEllenorzes | undefined = undefined
      if (!ellenorzesList.find(ellenorzes => ellenorzes.getUuid() === horgaszAdatRequest.uuid)) {
        ellenorzes = DatabaseEllenorzes.fromEllenorzesRequest(horgaszAdatRequest, horgaszAdatReply);
        ellenorzesList.push(ellenorzes);
      }
      await this.storageService.set(this.ELLENORZES_KEY, ellenorzesList.map(ellenorzes => ellenorzes.data));
      this.ellenorzesekSubject.next(ellenorzesList);
      return ellenorzes
    });
  }

  async insertEllenorzes(ellenorzes: EllenorzesiTevekenysegReplyRow) {
    return await this.ellenorzesMutex.runExclusive(async () => {
      const storedContent: Array<DatabaseEllenorzesRecord> = await this.storageService.get(this.ELLENORZES_KEY) ?? [];
      const ellenorzesList = storedContent.map(sEllenorzes => new DatabaseEllenorzes(sEllenorzes))
      const databaseEllenorzes = DatabaseEllenorzes.fromEllenorzes(ellenorzes)
      ellenorzesList.push(databaseEllenorzes);
      await this.storageService.set(this.ELLENORZES_KEY, ellenorzesList.map(ellenorzes => ellenorzes.data));
      this.ellenorzesekSubject.next(ellenorzesList);
      return databaseEllenorzes
    });
  }

  async insertEllenorzesMinositesRequest(minositesRequest: EllenorzesiTevekenysegMinositesRequest) {
    return await this.ellenorzesMutex.runExclusive(async () => {
      const storedContent: Array<DatabaseEllenorzesRecord> = await this.storageService.get(this.ELLENORZES_KEY) ?? [];
      const ellenorzesList = storedContent.map(sEllenorzes => new DatabaseEllenorzes(sEllenorzes))
      const ellenorzes = ellenorzesList.find(ellenorzes => ellenorzes.getUuid() === minositesRequest.uuid)
      if (ellenorzes) {
        ellenorzes.addMinosites(minositesRequest)
      }
      await this.storageService.set(this.ELLENORZES_KEY, ellenorzesList.map(ellenorzes => ellenorzes.data));
      this.ellenorzesekSubject.next(ellenorzesList);
      return ellenorzes
    });
  }

  async syncEllenorzesek(): Promise<void> {
    await this.ellenorzesMutex.runExclusive(async () => {
      const storedContent: Array<DatabaseEllenorzesRecord> = await this.storageService.get(this.ELLENORZES_KEY) ?? [];
      const ellenorzesList = storedContent.map(sEllenorzes => new DatabaseEllenorzes(sEllenorzes))
      if (storedContent.length > 0) {
        try {
          const request: BatchEllenorzesRequest = {
            ellenorzesRequestList: storedContent
            .filter(data => data.synchronized === false)
            .map(data => data.ellenorzesRequest)
          }
          if (request.ellenorzesRequestList.length > 0) {
          const synchronizedEllenorzesList = await firstValueFrom(this.ellenorAppControllerService.batchEllenorzes(request));
          synchronizedEllenorzesList.tevekenyseg.forEach(ellenorzes => {
            const databaseEllenorzes = ellenorzesList.find(sEllenorzes => sEllenorzes.getUuid() === ellenorzes.uuid)
            if (databaseEllenorzes) {
              databaseEllenorzes.synchronize(ellenorzes)
            }
          })
          await this.storageService.set(this.ELLENORZES_KEY, ellenorzesList.map(ellenorzes => ellenorzes.data));
          this.ellenorzesekSubject.next(ellenorzesList);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }
}
