import { Component, OnInit } from '@angular/core';
import { MegbizoDetails } from 'src/api';
import { AuthenticatedUser } from 'src/app/objects/authenticatedUser';
import { AuthenticationService, USER_KEY } from 'src/app/services/authentication.service';
import { StorageService } from 'src/app/services/storage.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
    standalone: false
})
export class LoginPage implements OnInit {

  authenticatedUser: AuthenticatedUser = new AuthenticatedUser('', '')
  megbizok: MegbizoDetails[] = [];
  passwordCheckbox = false;

  constructor(
    private storageService: StorageService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.storageService.get(USER_KEY).then((user) => {
      if (user) {
        this.authenticatedUser = user;
      }
    });
  }

  login() {
    this.authenticationService.login({
      username: this.authenticatedUser.loginId,
      password: this.authenticatedUser.password
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  showPassword(input: any): any {
    this.passwordCheckbox = !this.passwordCheckbox;
    input.type = input.type === 'password' ? 'text' : 'password';
  }

}
