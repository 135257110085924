import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EllenorAppControllerService, VizteruletTorzsadat } from 'src/api';
import { AuthenticationService } from './authentication.service';
import { TimerMutex } from '../objects/timer-mutex';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Utils } from './utils';

@Injectable({
  providedIn: 'root'
})
export class TorzsadatProviderService {

  vizteruletList: Observable<Array<VizteruletTorzsadat>>;

  //30 percig biztos nem kell frissiteni
  private timerMutex = new TimerMutex(30*60);

  private vizteruletListSubject = new BehaviorSubject<Array<VizteruletTorzsadat>>(null);

  constructor(
    private authenticationService: AuthenticationService,
    private ellenorAppControllerService: EllenorAppControllerService
  ) {
    this.vizteruletList = this.vizteruletListSubject.asObservable().pipe(
      filter(vizteruletList => Utils.hasValue(vizteruletList))
    );

    this.authenticationService.sssUser.pipe(takeUntilDestroyed()).subscribe(sssUser => {
      if (sssUser.isAuthenticated()){
        this.refreshTorzsadat();
      }
    });
  }

  private refreshTorzsadat(){
    this.timerMutex.runExclusive(() => {
      this.ellenorAppControllerService.vizteruletList().subscribe({
        next: result => this.vizteruletListSubject.next(result),
        error: () => {}
      })
    });
  }
}
