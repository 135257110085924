import { Component, OnInit } from '@angular/core';
import { AuthenticatedUser } from 'src/app/objects/authenticatedUser';
import { DatabaseEllenorzes } from 'src/app/objects/databaseEllenorzes';
import { VizteruletPipe } from 'src/app/pipes/vizterulet.pipe';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabaseService } from 'src/app/services/database.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { orderBy } from 'lodash';

@Component({
    selector: 'app-ellenorzesek',
    templateUrl: './ellenorzesek.page.html',
    styleUrls: ['./ellenorzesek.page.scss'],
    standalone: false
})
export class EllenorzesekPage implements OnInit {

  user: AuthenticatedUser = new AuthenticatedUser();
  searchText = '';
  filteredEllenorzesList: Array<DatabaseEllenorzes> = []
  ellenorzesek: Array<DatabaseEllenorzes> = []

  constructor(
    public authenticationService: AuthenticationService,
    private databaseService: DatabaseService,
    private vizteruletPipe: VizteruletPipe,
  ) {
    this.authenticationService.sssUser.subscribe(user => {
      if (user && user.trusteeId !== null) {
        this.user = user;
      }
    });
    this.databaseService.ellenorzesek.pipe(takeUntilDestroyed()).subscribe(ell => {
      this.ellenorzesek = ell
      this.doFilter()
    })
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.doFilter();
  }

  doFilter() {
    if (this.searchText && this.searchText.trim().length > 0) {
      const query = this.searchText.toLowerCase();
      this.filteredEllenorzesList = this.ellenorzesek.filter(ell => {
        const vizteruletNev = this.vizteruletPipe.transform(ell.getVizteruletId()).toString().toLowerCase()
        return vizteruletNev.indexOf(query) > -1 ||
            ell.getHorgaszAzonosito()?.toLowerCase().indexOf(query) > -1 ||
            ell.getKeresettAzonosito()?.toLowerCase().indexOf(query) > -1
      })
    } else {
      this.filteredEllenorzesList = this.ellenorzesek
    }
    this.filteredEllenorzesList = orderBy<DatabaseEllenorzes>(this.filteredEllenorzesList, ell => ell.getKliensIdopont(), 'desc')
  }

}
