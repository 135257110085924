import moment from 'moment';

export class Utils {

  static timestamp(date?: Date): Date {
    return moment(date ?? new Date()).format('YYYY-MM-DDTHH:mm:ss') as unknown as Date;
  }

  static date(date?: Date): Date {
    return moment(date ?? new Date()).format('YYYY-MM-DD') as unknown as Date;
  }

  static secondsBetween(date1: Date, date2: Date) {
    return Math.abs((date1.getTime() - date2.getTime()) / 1000);
  }

  static hasValue(object: any) {
    return object !== null && object !== undefined;
  }
}
