<ion-header>
  <ion-toolbar color="primary">
    <ion-thumbnail slot="start">
      <ion-img src="assets/logo-white.svg"></ion-img>
    </ion-thumbnail>
    <ion-title>Magyar Horgászkártya Ellenőr</ion-title>
  </ion-toolbar>
  <ion-fab vertical="top" horizontal="end" slot="fixed" *ngIf="authenticationService.isOfflineMukodes()">
    <ion-fab-button color="warning">
      <ion-text color="danger">
        Offline
      </ion-text>
    </ion-fab-button>
  </ion-fab>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row class="justify-content-center">
      <ion-col class="align-self-center" size-md="6" size-lg="5" size-xs="12">
        <div class="text-center">
          <h3>Bejelentkezés</h3>
        </div>
        <div class="padding" *ngIf="authenticatedUser">
          <ion-item>
            <ion-input name="username" type="email" placeholder="email@email.com"
                       [(ngModel)]="authenticatedUser.loginId" required></ion-input>
          </ion-item>
          <ion-item>
            <ion-input #input name="password" type="password" placeholder="jelszó"
                       [(ngModel)]="authenticatedUser.password" required>
            </ion-input>
            <div (click)="showPassword(input)">
              <ion-label for="checkbox" *ngIf="!passwordCheckbox">
                <ion-icon name="eye"></ion-icon>
              </ion-label>
              <ion-label for="checkbox" *ngIf="passwordCheckbox">
                <ion-icon name="eye-off"></ion-icon>
              </ion-label>
            </div>
            <ion-checkbox name="pswShow" class="ion-hide" id="checkbox" [(ngModel)]="passwordCheckbox">
            </ion-checkbox>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar *ngIf="authenticatedUser">
    <ion-button (click)="login()" size="large" color="primary"
                [disabled]="!authenticatedUser.loginId || !authenticatedUser.password" expand="block" class="margin">
      Bejelentkezés
    </ion-button>
    <ion-button (click)="logout()" size="large" expand="block" class="margin">Mégse</ion-button>
  </ion-toolbar>
</ion-footer>
