import { Pipe, PipeTransform } from '@angular/core';
import { AllamiJegy } from 'src/api';

@Pipe({
    name: 'allamiJegyStatusz',
    standalone: false
})
export class AllamiJegyStatuszEnumPipe implements PipeTransform {
    transform(value: AllamiJegy.StatuszEnum, args?: any): any {
        switch (value) {
            case AllamiJegy.StatuszEnum.ERVENYES: return 'Érvényes'
            case AllamiJegy.StatuszEnum.LEJART: return 'Lejárt'
            case AllamiJegy.StatuszEnum.VEGLEGESENSTORNOZOTT: return 'Véglegesen sztornózott'
            case AllamiJegy.StatuszEnum.ERVENYESSZTORNOJOVAHAGYASRAVAR: return 'Sztornó jóváhagyásra vár'
            case AllamiJegy.StatuszEnum.ELVESZETT: return 'Elveszett'
            case AllamiJegy.StatuszEnum.ERVENYESELVESZETTJOVAHAGYASRAVAR: return 'Elveszett, jóváhagyásra vár'
            case AllamiJegy.StatuszEnum.ERVENYESMEGSEMMISULTJOVAHAGYASRAVAR: return 'Megsemmisült, jóváhagyásra vár'
            case AllamiJegy.StatuszEnum.MEGSEMMISULT: return 'Megsemmisült'
            case AllamiJegy.StatuszEnum.VISSZAVONT: return 'Visszavont'
            case AllamiJegy.StatuszEnum.LEJARTLEADOTTFN: return 'Lejárt (leadott fogási napló)'
            case AllamiJegy.StatuszEnum.SZTORNOZOTT: return 'Sztornózott'
            case AllamiJegy.StatuszEnum.INAKTIV: return 'Inaktív'
            default: return value;
        }
    }
}
