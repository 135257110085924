import {Component, OnInit} from '@angular/core';
import {MegbizoDetails} from 'src/api';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {Router} from '@angular/router';
import {AuthenticatedUser} from 'src/app/objects/authenticatedUser';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
    standalone: false
})
export class DashboardPage implements OnInit {
  sssUser: AuthenticatedUser = new AuthenticatedUser();
  password: '';
  megbizok: MegbizoDetails[] = [];

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.megbizok = this.router.getCurrentNavigation().extras.state.megbizok;
    this.authenticationService.sssUser.subscribe(async sssUser => {
      this.sssUser = sssUser;
    });
  }

  logout() {
    if (this.sssUser && this.sssUser.trusteeId !== null && this.sssUser.entrustingId !== null) {
      this.router.navigate(['members', 'ellenorzes']);
    } else {
      this.authenticationService.logout();
    }
  }
}
