import {Pipe, PipeTransform} from '@angular/core';
import {VersenyzoiEngedely} from 'src/api';

@Pipe({
    name: 'versenyzoiengedelystatusz',
    standalone: false
})
export class VersenyzoiEngedelyStatuszEnumPipe implements PipeTransform {
  transform(value: VersenyzoiEngedely.StatuszEnum, args?: any): any {
    switch (value) {
      case VersenyzoiEngedely.StatuszEnum.ERVENYES: return 'érvényes'
      case VersenyzoiEngedely.StatuszEnum.LEJART: return 'lejárt'
      default: return value;
    }
  }
}
