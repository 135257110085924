import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BackgroundGeolocationPlugin, Location, WatcherOptions } from '@capacitor-community/background-geolocation';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { Network } from '@capacitor/network';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, interval } from 'rxjs';
import { AuthRestControllerService, EllenorLokacioRogzitesRequest, EllenorSssUser, GPSKoordinata, JogosultsagControllerService, SssUser } from 'src/api';
import { Utils } from 'src/app/services/utils';
import { environment } from 'src/environments/environment';
import { AuthenticatedUser } from '../objects/authenticatedUser';
import { DatabaseService } from './database.service';
import { OfflineDataService } from './offline-data.service';
import { StorageService } from './storage.service';

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");


export const USER_KEY = 'USER_KEY';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject(true);
  sssUser = new BehaviorSubject<AuthenticatedUser>(new AuthenticatedUser());
  requestRepeat = new BehaviorSubject<boolean>(false);

  geolocationOptions = {
    timeout: 1000*20,
    enableHighAccuracy: true,
    maximumAge: 0
  } as PositionOptions;

  lastGeolocation: Location
  private watcherId: string;

  constructor(
    private platform: Platform,
    private menuController: MenuController,
    private databaseService: DatabaseService,
    private jogosultsagControllerService: JogosultsagControllerService,
    private storageService: StorageService,
    private authRestControllerService: AuthRestControllerService,
    private alertCtrl: AlertController,
    private offlineDataService: OfflineDataService
  ) {

    //20 masodpercenkent ellenorizzuk hogy van-e adat
    interval(20000).pipe(takeUntilDestroyed()).subscribe(() => {
      this.sendLokaciokToServer().then(() => {});
      this.sendEllenorzesekToServer().then(() => {});
    });

    this.platform.ready().then(() => {
      Network.addListener('networkStatusChange', status => {
        if (status.connected) {
          console.log('network was connected :-)');
          if(this.isOfflineMukodes()) {
            this.offlineDataService.refreshDataFromServer();
          }
        } else {
          console.log('network was disconnected :-(');
        }
        this.onlineStatus.next(status.connected);
      });
    });
  }

  async checkUser() {
    if (this.authRestControllerService) {
      const user = await firstValueFrom(this.authRestControllerService.currentPrincipal())
      const authenticatedUser: AuthenticatedUser = AuthenticationService.authenticatedUserFromSssUser(user);
      if (authenticatedUser && authenticatedUser.authorities && authenticatedUser.authorities.includes('ELLENOR')) {
        this.storageService.set(USER_KEY, authenticatedUser).then(() => {
          this.sssUser.next(authenticatedUser);
        });
      } else if (authenticatedUser && authenticatedUser.entrustingId !== null) {
        const alertMesage = await this.alertCtrl.create({
          header: `Nincs jogosultságod az applikáció használatához!`,
          message: '',
          buttons: ['OK']
        });
        await alertMesage.present();
        this.sssUser.next(new AuthenticatedUser());
      }
    }
  }

  async login(form) {
    try {
      const user = await firstValueFrom(this.authRestControllerService.login(form.username, form.password));
      const authenticatedUser: AuthenticatedUser = AuthenticationService.authenticatedUserFromSssUser(user);
      authenticatedUser.password = form.password;
      this.storageService.set(USER_KEY, authenticatedUser).then(() => {
        this.sssUser.next(authenticatedUser);
      });
    } catch (error) {
      const alertMesage = await this.alertCtrl.create({
        header: `Hiba`,
        message: 'Hibás felhasználónév/jelszó',
        buttons: ['OK']
      });
      await alertMesage.present();
      this.storageService.remove(USER_KEY).then(() => {
        this.sssUser.next(new AuthenticatedUser());
      });
    }
  }

  async reLogin(){
    const user = await this.storageService.get(USER_KEY);
    if (user) {
      console.log("Background re-authentication...")
      const sssUser: AuthenticatedUser = user;
      try {
        await firstValueFrom(this.authRestControllerService.login(sssUser.loginId, sssUser.password));
        await firstValueFrom(this.jogosultsagControllerService.megbizoKivalasztas({ megbizoSzereploId: sssUser.entrustingId }));
        this.requestRepeat.next(true);
      } catch (error) {
        await this.logout();
      }
    }
  }

  async logout() {
    try {
      await firstValueFrom(this.authRestControllerService.logout());
    } catch (error) {
    } finally {
      this.sssUser.next(new AuthenticatedUser());
      if(this.watcherId) {
        await BackgroundGeolocation.removeWatcher({id: this.watcherId})
        this.watcherId = null
      }
      await this.menuController.close();
    }
  }

  isAuthenticated() {
    return !!this.sssUser.value && this.sssUser.value.isAuthenticated();
  }

  isFullyAuthenticated() {
    //vizterulet valasztast kovetoen van teljesen bejelentkezve
    return !!this.sssUser.value && this.sssUser.value.isFullyAuthenticated();
  }

  isHasEntrustingId() {
    return !!this.sssUser.value && this.sssUser.value.isHasEntrustingId();
  }

  private async saveLocation(user, location) {
    const lokacioReq: EllenorLokacioRogzitesRequest = {
      ellenorSzemelyId: user.trusteeId,
      idopont: Utils.timestamp(),
      szervezetId: user.entrustingId,
      koordinata: {
        hosszusagiFok: location.longitude,
        szelessegiFok: location.latitude
      }
    };
    await this.databaseService.insertLokacio(lokacioReq);
  }

  private static authenticatedUserFromSssUser(sssUser: SssUser): AuthenticatedUser {
    const authenticatedUser: AuthenticatedUser = new AuthenticatedUser();
    authenticatedUser.active = sssUser.active;
    authenticatedUser.authorities = sssUser.authorities;
    authenticatedUser.authorityChannel = sssUser.authorityChannel;
    authenticatedUser.email = sssUser.email;
    authenticatedUser.entrustingId = sssUser.entrustingId;
    authenticatedUser.lastLoggedIn = sssUser.lastLoggedIn;
    authenticatedUser.loginId = sssUser.loginId;
    authenticatedUser.trusteeId = sssUser.trusteeId;
    return authenticatedUser;
  }

  async startTracking(user: EllenorSssUser) {
    //csak mobilon mukodik
    const platform = Capacitor.getPlatform();
    if(platform === 'ios' || platform === 'android') {
      if(this.watcherId) {
        await BackgroundGeolocation.removeWatcher({id: this.watcherId})
        this.watcherId = null
      }

      const config: WatcherOptions = {
        distanceFilter: environment.geolocationConfig.distanceFilter,
        backgroundTitle: 'Location tracking',
        backgroundMessage: 'tacking message',
        requestPermissions: true,
        stale: true
      };

      this.watcherId = await BackgroundGeolocation.addWatcher(config, async (location, error) => {
        if (error) {
          if (error.code === "NOT_AUTHORIZED") {
            const alertMesage = await this.alertCtrl.create({
              header: `Engedélyezd a helymeghatározást az alkalmazásnak!`,
              message: '',
              buttons: ['OK']
            });
            await alertMesage.present();
            await alertMesage.onDidDismiss();
            await BackgroundGeolocation.openSettings();
          }
          return console.error(error);
        }
        // interval filter
        if (!this.lastGeolocation || !environment.geolocationConfig.interval
          || (location.time - this.lastGeolocation.time > environment.geolocationConfig.interval)) {
          console.log("TRACKING: " + location.longitude + " - " + location.latitude);
          this.lastGeolocation = location;
          this.saveLocation(user, location);
        }
      })
    }
  }

  async getLocation(): Promise<GPSKoordinata> {
    let resp: GPSKoordinata;
    //const lastGeo = this.lastGeolocation.getValue();
    const lastGeo = this.lastGeolocation;
    //if (this.isOfflineMukodes() && lastGeo) {
    if (lastGeo) {
      console.log("LASTBACKGEOLOCATION")
      resp = {
        hosszusagiFok: lastGeo.longitude,
        szelessegiFok: lastGeo.latitude
      }
    } else {
      console.log("CURRENTPOSITION");
      try {
        const geoposition = await Geolocation.getCurrentPosition(this.geolocationOptions);
        resp = {
          hosszusagiFok: geoposition.coords.longitude,
          szelessegiFok: geoposition.coords.latitude
        }
      } catch (error){
        console.error(error)
      }
    }
    return resp;
  }

  isOnlineMukodes() : boolean{
    return this.onlineStatus.getValue();
  }

  isOfflineMukodes() : boolean{
    return !this.onlineStatus.getValue();
  }

  private async sendLokaciokToServer() {
    if (this.isOnlineMukodes() && this.isFullyAuthenticated()) {
      await this.databaseService.syncLokaciok();
    }
  }

  private async sendEllenorzesekToServer() {
    if (this.isOnlineMukodes() && this.isFullyAuthenticated()) {
      await this.databaseService.syncEllenorzesek();
    }
  }
}
