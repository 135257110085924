<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-thumbnail slot="start" (click)="onConnectionStateChange()">
      <ion-img src="assets/logo-white.svg"></ion-img>
    </ion-thumbnail>
    <ion-title>
      Ellenőrzés
    </ion-title>
  </ion-toolbar>
  <ion-fab vertical="top" horizontal="end" slot="fixed" *ngIf="authenticationService.isOfflineMukodes()">
    <ion-fab-button color="warning" style="width: 40px; height: 40px;">
      <ion-text color="danger">
        Offline
      </ion-text>
    </ion-fab-button>
  </ion-fab>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        <h4><span
            *ngIf="user && user.selectedVizterulet">{{user.entrustingNev}}</span><span
            *ngIf="user && !user.selectedVizterulet">-</span>
        </h4>
      </ion-card-title>
      <ion-card-subtitle (click)="editSelectVizter()">
        <h5><span *ngIf="user && user.selectedVizterulet">{{user.selectedVizteruletNev}}</span><span
            *ngIf="user && !user.selectedVizterulet">-</span>
          <ion-icon name="create" class="float-right"></ion-icon>
        </h5>
      </ion-card-subtitle>
    </ion-card-header>
  </ion-card>
  <ion-item-divider></ion-item-divider>
  <ion-button class="margin-top" expand="full" (click)="qrCodeScanner()"
    [disabled]="loading || user && !user.selectedVizterulet">QR kód beolvasás</ion-button>
  <ion-item-divider></ion-item-divider>
  <ion-list class="margin-top">
    <ion-item lines="none">
      <ion-label position="floating">Keresendő kifejezés</ion-label>
      <ion-input [(ngModel)]="searchText"></ion-input>
    </ion-item>
  </ion-list>
  <ion-button expand="full" (click)="onSearchHorgasz()"
    [disabled]="loading || user && !user.selectedVizterulet || !searchText">Tovább
  </ion-button>
</ion-content>
