import {VizteruletTorzsadat} from 'src/api';

export class AuthenticatedUser {
  active?: boolean;
  authorities?: Array<string>;
  authorityChannel?: string;
  email?: string;
  entrustingId?: number;
  lastLoggedIn?: Date;
  loginId?: string;
  trusteeId?: number;

  selectedVizterulet?: VizteruletTorzsadat;
  selectedVizteruletNev?: string;
  password?: string;
  entrustingNev?: string;

  constructor(loginId?: string, password?: string) {
    this.loginId = loginId,
    this.password = password
  }

  isAuthenticated() {
    return !!this.trusteeId;
  }

  isFullyAuthenticated() {
    //vizterulet valasztast kovetoen van teljesen bejelentkezve
    return !!this.selectedVizterulet;
  }

  isHasEntrustingId() {
    return !!this.entrustingId;
  }
}
