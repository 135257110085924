import {Injectable} from '@angular/core';

import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private auth: AuthenticationService) {
  }

  canActivate(): boolean {
    return this.auth.isAuthenticated();
  }
}
