<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-thumbnail slot="start">
      <ion-img src="assets/logo-white.svg"></ion-img>
    </ion-thumbnail>
    <ion-title>Magyar Horgászkártya Ellenőr</ion-title>
  </ion-toolbar>
</ion-header>
<ion-footer>
  <ion-toolbar>
    <div>
      <ion-button (click)="logout()" size="large" expand="block" margin>Mégse</ion-button>
    </div>
  </ion-toolbar>
</ion-footer>