import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';

import { Platform } from '@ionic/angular';
import { filter } from 'rxjs';
import { MegbizoDetails } from 'src/api';
import { VizteruletPipe } from 'src/app/pipes/vizterulet.pipe';
import { AuthenticationService } from './services/authentication.service';
import { DatabaseService } from './services/database.service';
import { TorzsadatProviderService } from './services/torzsadat-provider.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: false
})
export class AppComponent {
  loggedIn = false;
  hasValasztottVizterulet = false;
  addedValasztoMenu = false;
  megbizok: MegbizoDetails[] = [];

  public appLoggedInPages = [
    {
      title: 'Vízterület választó',
      url: '/members/vizteruletek',
      icon: ''
    }
  ];

  public appHasVizteruletPages = [
    {
      title: 'Ellenőrzés',
      url: '/members/ellenorzes',
      icon: ''
    },
    {
      title: 'Előzmények',
      url: '/members/ellenorzesek',
      icon: ''
    },
    {
      title: 'Vízterület választó',
      url: '/members/vizteruletek',
      icon: ''
    }
  ];

  public appLoggedOutPages = [
    {
      title: 'Bejelentkezés',
      url: '/login',
      icon: ''
    },
  ];
  selectedPath = '';

  constructor(
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private router: Router,
    private databaseService: DatabaseService
  ) {
    this.initializeApp();
    inject(TorzsadatProviderService);
  }

  logout() {
    this.authenticationService.logout();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      //StatusBar.styleDefault();
      //StatusBar.hide();
      //StatusBar.overlaysWebView(true);
      SplashScreen.hide();
    });
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event) => {
      this.selectedPath = event.url;
    });
    this.authenticationService.sssUser.subscribe(async sssUser => {
      if (sssUser && !sssUser.trusteeId) {
        this.loggedIn = false;
        this.router.navigate(['login']);
      }
      if (sssUser && sssUser.trusteeId && !sssUser.entrustingId && !sssUser.selectedVizterulet) {
        this.loggedIn = true;
        this.hasValasztottVizterulet = false;
        this.router.navigate(['members', 'vizteruletek'], {replaceUrl: true});
      }
      if (sssUser && sssUser.trusteeId && sssUser.entrustingId !== null && sssUser.selectedVizterulet) {
        this.loggedIn = true;
        this.hasValasztottVizterulet = true;
        await this.databaseService.initEllenorzesList(sssUser)
        this.router.navigate(['members', 'ellenorzes'], {replaceUrl: true});
      }
      if (sssUser && sssUser.trusteeId && sssUser.entrustingId !== null && !sssUser.selectedVizterulet) {
        this.loggedIn = true;
        this.hasValasztottVizterulet = false;
        this.router.navigate(['members', 'vizteruletek'], {replaceUrl: true});
      }
    });
  }

  navigateDashboard() {
    this.router.navigate(['members', 'dashboard'], { state: { megbizok: this.megbizok } });
  }


}
