<ion-card *ngIf="ellenorzes">
  <ion-card-header>
    <ion-card-subtitle>
      <ion-label>
        <div class="kereses">
          <!-- <ion-icon name="warning" slot="start"></ion-icon> -->
          <span>{{keresettEgyediAzonosito ?? '-'}} | {{kliensIdopont|date:'yyyy-MM-dd HH:mm'}}</span>
          <ion-icon *ngIf="!syncronized" name="cloud-offline-outline"></ion-icon>
        </div>
      </ion-label>
      <div>
        <ion-label class="clickableLabel" *ngIf="minosites" (click)="onEllenorzesClick()">
          <ion-text color="success" *ngIf="minosites === MINOSITES.RENDBEN">
            {{minosites | minosites}}</ion-text>
          <ion-text color="danger" *ngIf="minosites === MINOSITES.SZABALYSERTES">
            {{minosites | minosites}}
            <ng-container *ngIf="ejegyInaktivalas || enaploInaktivalas || fogasiNaploVisszatartas">
              ({{ejegyInaktivalas ? 'e-jegy inaktiválás' : ''}}
              {{enaploInaktivalas ? ', e-naplo inaktiválás' : ''}}
              {{fogasiNaploVisszatartas ? (ejegyInaktivalas ? ', ' : '') + 'fogási naplo visszatartás' : ''}})
            </ng-container>
          </ion-text>
        </ion-label>
        <ion-label class="clickableLabel" *ngIf="!minosites && horgaszTalalat"
          (click)="onEllenorzesClick()">
          <ion-text color="primary">Tovább az ellenőrzésre</ion-text>
        </ion-label>
      </div>
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <p *ngIf="horgaszTalalat && horgaszAzonosito">{{horgaszAzonosito}},
      {{vezeteknev}}
      {{keresztnev}}</p>
    <p *ngIf="!horgaszTalalat">
      <ion-text color="warning">Nem volt találat</ion-text>
    </p>
    {{vizteruletId | vizterulet}}
  </ion-card-content>
</ion-card>
