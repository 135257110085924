import { Pipe, PipeTransform } from '@angular/core';
import { TeruletiKiegeszitoJegy } from 'src/api';

@Pipe({
    name: 'kiegeszitojegyidoszak',
    standalone: false
})
export class KiegeszitoJegyIdoszakEnumPipe implements PipeTransform {
  transform(value: TeruletiKiegeszitoJegy.IdoszakEnum, args?: any): any {
    switch (value) {
      case TeruletiKiegeszitoJegy.IdoszakEnum.EVES: return 'Éves';
      case TeruletiKiegeszitoJegy.IdoszakEnum.FELEVES: return 'Féléves';
      case TeruletiKiegeszitoJegy.IdoszakEnum.NAPI: return 'Napi';
      case TeruletiKiegeszitoJegy.IdoszakEnum.ORA24: return '24 órás';
      case TeruletiKiegeszitoJegy.IdoszakEnum.ORA48: return '48 órás';
      case TeruletiKiegeszitoJegy.IdoszakEnum.ORA72: return '72 órás';
      case TeruletiKiegeszitoJegy.IdoszakEnum.EJSZAKAI: return 'Éjszakai';
      case TeruletiKiegeszitoJegy.IdoszakEnum.EVESRESZLET1: return 'Éves részlet 1';
      case TeruletiKiegeszitoJegy.IdoszakEnum.EVESRESZLET2: return 'Éves részlet 2';
      case TeruletiKiegeszitoJegy.IdoszakEnum.NAP10: return '10 napos';
      case TeruletiKiegeszitoJegy.IdoszakEnum.NAP2: return '2 napos';
      case TeruletiKiegeszitoJegy.IdoszakEnum.NAP3: return '3 napos';
      case TeruletiKiegeszitoJegy.IdoszakEnum.NAP7: return '7 napos';
      default: return value;
    }
  }
}
