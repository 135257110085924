import {Pipe, PipeTransform} from '@angular/core';
import {EllenorzesiTevekenyseg} from 'src/api';

@Pipe({
    name: 'minosites',
    standalone: false
})
export class MinositesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case EllenorzesiTevekenyseg.MinositesEnum.RENDBEN: return 'Rendben';
      case EllenorzesiTevekenyseg.MinositesEnum.SZABALYSERTES: return 'Szabálysértés';
      default: return value;
    }
  }
}
