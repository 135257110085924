/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HorgaszAdatReply } from './horgaszAdatReply';

export interface EllenorzesiTevekenysegReplyRow { 
    anyjaNeve?: string;
    cim?: string;
    ejegyInaktivalas?: boolean;
    ellenorNev?: string;
    ellenorSzemelyId?: number;
    enaploInaktivalas?: boolean;
    fogasiNaploVisszatartas?: boolean;
    horgaszAdatReply?: HorgaszAdatReply;
    horgaszAzonosito?: string;
    horgaszTalalat?: boolean;
    id?: number;
    idopont?: Date;
    keresettEgyediAzonosito?: string;
    keresztnev?: string;
    kliensIdopont?: Date;
    minosites?: EllenorzesiTevekenysegReplyRow.MinositesEnum;
    szabalysertesId?: number;
    szabalysertesSorszama?: string;
    szervezetId?: number;
    szervezetNev?: string;
    uuid?: string;
    vezeteknev?: string;
    vizteruletId?: number;
    vizteruletNev?: string;
    vizteruletVizterkod?: string;
}
export namespace EllenorzesiTevekenysegReplyRow {
    export type MinositesEnum = 'RENDBEN' | 'SZABALYSERTES';
    export const MinositesEnum = {
        RENDBEN: 'RENDBEN' as MinositesEnum,
        SZABALYSERTES: 'SZABALYSERTES' as MinositesEnum
    };
}