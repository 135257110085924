import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { DashboardPageModule } from './dashboard/dashboard.module';
import { VizteruletekPageModule } from './vizteruletek/vizteruletek.module';
import { EllenorzesPageModule } from './ellenorzes/ellenorzes.module';
import { HorgaszAdatokPageModule } from './horgasz-adatok/horgasz-adatok.module';
import { EllenorzesekPageModule } from './ellenorzesek/ellenorzesek.module';

const routes: Routes = [
  {path: 'dashboard', loadChildren: () => DashboardPageModule},
  {path: 'vizteruletek', loadChildren: () => VizteruletekPageModule},
  {path: 'ellenorzes', loadChildren: () => EllenorzesPageModule},
  {path: 'horgasz-adatok', loadChildren: () => HorgaszAdatokPageModule},
  {path: 'ellenorzesek', loadChildren: () => EllenorzesekPageModule},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule {
}
