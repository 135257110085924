import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { EllenorAppControllerService, EllenorSssUser, VizteruletRequest, VizteruletTorzsadat } from 'src/api';
import { AuthenticatedUser } from 'src/app/objects/authenticatedUser';
import { AuthenticationService, USER_KEY } from 'src/app/services/authentication.service';
import { OfflineDataService } from 'src/app/services/offline-data.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-vizteruletek',
    templateUrl: './vizteruletek.page.html',
    styleUrls: ['./vizteruletek.page.scss'],
    standalone: false
})
export class VizteruletekPage implements OnInit, OnDestroy {

  vizteruletList: Array<VizteruletTorzsadat> = [];
  allVizterek: Array<VizteruletTorzsadat> = [];
  user: AuthenticatedUser = new AuthenticatedUser();

  private authSubscription: Subscription;

  constructor(
    private ellenorAppControllerService: EllenorAppControllerService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private router: Router,
    private offlineDataService: OfflineDataService
  ) {
  }

  ngOnInit() {
    this.authSubscription = this.authenticationService.sssUser.subscribe(pUser => {
      if (pUser && pUser.trusteeId != null) {
        this.user = pUser;
      }
    });
  }

  ionViewWillEnter() {
    this.getVizterek();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  async getVizterek() {
    try {
      this.allVizterek = await this.ellenorAppControllerService.valaszthatoVizteruletek().toPromise();
      this.vizteruletList = [...this.allVizterek];
    } catch (error) {
      console.log(error);
    }
  }

  async selectVizter(vizter: VizteruletTorzsadat) {
    try {
      const request: VizteruletRequest = { id: vizter.id }
      const sssUser = await firstValueFrom(this.ellenorAppControllerService.vizteruletKivalasztas(request));
      const authethenticatedUser = this.authenticatedUserFromEllenorSssUser(sssUser);
      authethenticatedUser.selectedVizterulet = vizter;
      this.storageService.set(USER_KEY, authethenticatedUser).then(() => {
        this.authenticationService.sssUser.next(authethenticatedUser);
        this.offlineDataService.initDataFromServer({id: vizter.id});
      });
      await this.authenticationService.startTracking(sssUser);
      const navigationExtras: NavigationExtras = {
        queryParams: { special: JSON.stringify(vizter) }
      };
      await this.router.navigate(['members', 'ellenorzes'], navigationExtras);
    } catch (err) {
      console.log(err);
    }
  }


  getItems(ev: any) {
    // Reset items back to all the items
    this.vizteruletList = [...this.allVizterek];

    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.vizteruletList = this.vizteruletList.filter((item) => {
        return (item.nev.toLowerCase().indexOf(val.toLowerCase()) > -1) || (item.vizterkod.toLowerCase().indexOf(val.toLowerCase()) > -1);
      });
    }
  }

  private authenticatedUserFromEllenorSssUser(sssUser: EllenorSssUser): AuthenticatedUser {
    const authenticatedUser: AuthenticatedUser = this.user;
    authenticatedUser.active = sssUser.active;
    authenticatedUser.authorities = sssUser.authorities;
    authenticatedUser.authorityChannel = sssUser.authorityChannel;
    authenticatedUser.email = sssUser.email;
    authenticatedUser.entrustingId = sssUser.entrustingId;
    authenticatedUser.lastLoggedIn = sssUser.lastLoggedIn;
    authenticatedUser.loginId = sssUser.loginId;
    authenticatedUser.trusteeId = sssUser.trusteeId;
    authenticatedUser.selectedVizteruletNev = sssUser.vizteruletNev;
    authenticatedUser.entrustingNev = sssUser.entrustingNev;
    return authenticatedUser;
  }

}
