import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { EllenorzesekPage } from './ellenorzesek.page';
import { MinositesPipe } from 'src/app/pipes/minosites.pipe';
import { EllenorzesListItem } from './ellenorzes-list-item/ellenorzes-list-item.component';
import { VizteruletPipe } from 'src/app/pipes/vizterulet.pipe';

const routes: Routes = [
  {
    path: '',
    component: EllenorzesekPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    VizteruletPipe
  ],
  declarations: [
    EllenorzesekPage,
    MinositesPipe,
    EllenorzesListItem
  ]
})
export class EllenorzesekPageModule { }
