<ion-header>
  <ion-toolbar>
    <ion-title >Szabálysértés</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="ion-padding">
      <ion-checkbox labelPlacement="end" name="pswShow" [(ngModel)]="ejegyInaktivalas" (ionChange)="changeEnaplo()">
        e-jegy inaktiválás
      </ion-checkbox>
    </ion-row>
    <ion-row class="ion-padding" *ngIf="data.hasEnaplo">
      <ion-checkbox labelPlacement="end" name="pswShow" [(ngModel)]="enaploInaktivalas" (ionChange)="changeEjegy()">
        e-napló inaktiválás
      </ion-checkbox>
    </ion-row>
    <ion-row class="ion-padding" *ngIf="!data.hasEnaplo">
      <ion-checkbox labelPlacement="end" name="pswShow" [(ngModel)]="fogasiNaploVisszatartas">
        papír alapú fogási napló visszatartás
      </ion-checkbox>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button color="medium" (click)="cancel()" expand="block" class="margin">Vissza</ion-button>
        </ion-col>
        <ion-col>
          <ion-button color="danger" (click)="szabalysertesClick()" expand="block" class="margin">Szabálysértés</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
