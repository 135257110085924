import { EllenorzesiTevekenysegMinositesRequest, EllenorzesiTevekenysegReplyRow, HorgaszAdatReply, HorgaszAdatRequest } from 'src/api';
import { DatabaseEllenorzesRecord } from './databaseEllenorzesRecord';

export class DatabaseEllenorzes {
    data: DatabaseEllenorzesRecord

    constructor(data: DatabaseEllenorzesRecord) {
        this.data = data
    }

    public static fromEllenorzesRequest(horgaszAdatRequest?: HorgaszAdatRequest, horgaszAdatReply?: HorgaszAdatReply): DatabaseEllenorzes {
        const data: DatabaseEllenorzesRecord = {
            synchronized: false,
            ellenorzesRequest: {
                horgaszAdatRequest,
                horgaszAdatReply
            }
        }
        return new DatabaseEllenorzes(data)
    }

    public static fromEllenorzes(ellenorzes?: EllenorzesiTevekenysegReplyRow): DatabaseEllenorzes {
        const data = {
            synchronized: true,
            ellenorzes: ellenorzes
        }
        return new DatabaseEllenorzes(data)
    }

    addMinosites(minositesRequest: EllenorzesiTevekenysegMinositesRequest) {
        if (this.data.ellenorzesRequest) {
            this.data.ellenorzesRequest.minositesRequest = minositesRequest
        } else {
            this.data.ellenorzesRequest = {minositesRequest}
        }
        this.data.synchronized = false
    }

    synchronize(ellenorzes: EllenorzesiTevekenysegReplyRow) {
        this.data.ellenorzes = ellenorzes
        this.data.ellenorzesRequest = undefined
        this.data.synchronized = true
    }

    getUuid(): string {
        return this.data.ellenorzes ? this.data.ellenorzes.uuid : this.data.ellenorzesRequest?.horgaszAdatRequest?.uuid
    }

    getVizteruletId() {
        return this.data.ellenorzes ? this.data.ellenorzes.vizteruletId : this.data.ellenorzesRequest?.horgaszAdatRequest?.vizteruletId
    }

    getHorgaszAzonosito() {
        return this.data.ellenorzes ? this.data.ellenorzes.horgaszAzonosito : this.data.ellenorzesRequest?.horgaszAdatReply?.horgaszAzonosito
    }

    getKeresettAzonosito() {
        return this.data.ellenorzes ? this.data.ellenorzes.keresettEgyediAzonosito : this.data.ellenorzesRequest?.horgaszAdatRequest?.egyediAzonosito
    }

    getKliensIdopont() {
      return this.data.ellenorzes?.kliensIdopont ?? this.data.ellenorzesRequest?.horgaszAdatRequest?.kliensIdopont
    }
}
