import { Injectable } from '@angular/core';
import { DatabaseEllenorzes } from '../objects/databaseEllenorzes';

@Injectable({
  providedIn: 'root'
})
export class SelectedEllenorzesService {

  private ellenorzes: DatabaseEllenorzes

  constructor() { }

  set(ellenorzes: DatabaseEllenorzes){
    this.ellenorzes = ellenorzes;
  }

  get(){
    return this.ellenorzes;
  }

  getHorgaszAdat(){
    return this.ellenorzes.data.ellenorzes ? this.ellenorzes.data.ellenorzes.horgaszAdatReply : this.ellenorzes.data.ellenorzesRequest?.horgaszAdatReply;
  }

  isMinositett(){
    return !!this.ellenorzes.data.ellenorzes?.minosites ? true : !!this.ellenorzes.data.ellenorzesRequest?.minositesRequest
  }

}
