import { Pipe, PipeTransform } from '@angular/core';
import { VizteruletTorzsadat } from 'src/api';
import { TorzsadatProviderService } from '../services/torzsadat-provider.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Pipe({
  name: 'vizterulet',
  standalone: true
})
export class VizteruletPipe implements PipeTransform {

  private vizteruletList: Array<VizteruletTorzsadat>;

  constructor(
    private torzsadatProviderService: TorzsadatProviderService,
  ) {
    this.torzsadatProviderService.vizteruletList.pipe(takeUntilDestroyed()).subscribe(
      result => this.vizteruletList = result
    );
  }

  transform(value: number | number[]): string | number {
    if (Array.isArray(value)) {
      return value.map(id => this.transformVizterulet(id)).join(', ');
    } else {
      return this.transformVizterulet(value);
    }
  }

  private transformVizterulet(vtid: number){
    const vizterulet = this.vizteruletList?.find(vt => vt.id === vtid);
    if(vizterulet){
      const horinfoNyilvantartas = vizterulet.nyilvantartas === 'HORINFO';
      return horinfoNyilvantartas ? vizterulet.megjelenitesiNev : vizterulet.nev;
    } else {
      return vtid;
    }
  }
}
