import { Pipe, PipeTransform } from '@angular/core';
import { TeruletiJegy } from 'src/api';

@Pipe({
    name: 'teruletiJegy',
    standalone: false
})
export class TeruletiJegyPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case TeruletiJegy.JogcimEnum.BONUSZ: return 'Bónusz';
      case TeruletiJegy.JogcimEnum.FELNOTT: return 'Felnőtt horgász';
      case TeruletiJegy.JogcimEnum.FELNOTTEMELTARAS: return 'Felnőtt emeltáras horgász';
      case TeruletiJegy.JogcimEnum.FELNOTTIHJ: return 'Felnőtt horgász (időszaki horgászati jogosultsággal)';
      case TeruletiJegy.JogcimEnum.FELNOTTJOGFENNTARTOIHJ: return 'Felnőtt jogfenntartó horgász (időszaki horgászati jogosultsággal)';
      case TeruletiJegy.JogcimEnum.FELNOTTKEDVEZMENYEZETT: return 'Felnőtt kedvezményezett horgász';
      case TeruletiJegy.JogcimEnum.FELNOTTMENTESITETT: return 'Felnőtt mentesített horgász';
      case TeruletiJegy.JogcimEnum.FELNOTTKULFOLDI: return 'Felnőtt külföldi horgász';
      case TeruletiJegy.JogcimEnum.FELNOTTTURISTA: return 'Felnőtt turista horgász';
      case TeruletiJegy.JogcimEnum.GYERMEK: return 'Gyermek horgász';
      case TeruletiJegy.JogcimEnum.GYERMEKUSZOS: return 'Gyermek úszós horgász';
      case TeruletiJegy.JogcimEnum.IFJUSAGI: return 'Ifjúsági horgász';
      case TeruletiJegy.JogcimEnum.IFJUSAGIEMELTARAS: return 'Ifjúsági emeltáras horgász';
      case TeruletiJegy.JogcimEnum.IFJUSAGIIHJ: return 'Ifjúsági horgász (időszaki horgászati jogosultsággal)';
      case TeruletiJegy.JogcimEnum.IFJUSAGIKULFOLDI: return 'Ifjúsági külföldi horgász';
      case TeruletiJegy.JogcimEnum.IFJUSAGITURISTA: return 'Ifjúsági turista horgász';
      case TeruletiJegy.JogcimEnum.SZOLGALATI: return 'Szolgálati horgász';
      case TeruletiJegy.JogcimEnum.TISZTSEGVISELOI: return 'Tisztségviselői horgász';
      case TeruletiJegy.JogcimCsoportEnum.ALTALANOS: return 'Általános';
      case TeruletiJegy.JogcimCsoportEnum.BOJLIS: return 'Bojlis';
      case TeruletiJegy.JogcimCsoportEnum.PARTI: return 'Parti';
      case TeruletiJegy.JogcimCsoportEnum.SPORTCELU: return 'Sportcélú';
      case TeruletiJegy.IdoszakEnum.EVES: return 'Éves';
      case TeruletiJegy.IdoszakEnum.FELEVES: return 'Féléves';
      case TeruletiJegy.IdoszakEnum.NAPI: return 'Napi';
      case TeruletiJegy.IdoszakEnum.ORA24: return '24 órás';
      case TeruletiJegy.IdoszakEnum.ORA48: return '48 órás';
      case TeruletiJegy.IdoszakEnum.ORA72: return '72 órás';
      case TeruletiJegy.IdoszakEnum.EJSZAKAI: return 'Éjszakai';
      case TeruletiJegy.IdoszakEnum.EVESRESZLET1: return 'Éves részlet 1';
      case TeruletiJegy.IdoszakEnum.EVESRESZLET2: return 'Éves részlet 2';
      case TeruletiJegy.IdoszakEnum.NAP10: return '10 napos';
      case TeruletiJegy.IdoszakEnum.NAP2: return '2 napos';
      case TeruletiJegy.IdoszakEnum.NAP3: return '3 napos';
      case TeruletiJegy.IdoszakEnum.NAP7: return '7 napos';
      default: return value;
    }
  }
}
