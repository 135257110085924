<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button>
      </ion-back-button>
      <ion-menu-button>
      </ion-menu-button>
    </ion-buttons>
    <ion-thumbnail slot="start">
      <ion-img src="assets/logo-white.svg"></ion-img>
    </ion-thumbnail>
    <ion-title>
      Horgász adatai
    </ion-title>
  </ion-toolbar>
  <ion-fab vertical="top" horizontal="end" slot="fixed" *ngIf="authenticationService.isOfflineMukodes()">
    <ion-fab-button color="warning">
      <ion-text color="danger">
        Offline
      </ion-text>
    </ion-fab-button>
  </ion-fab>
</ion-header>

<ion-content fullscreen class="ion-padding">
  <div *ngIf="selectedHorgasz">
    <ion-list>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">Ellenőrzés időpontja </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>{{selectedHorgasz.idopont | date:'yyyy-MM-dd HH:mm'}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">Név</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>{{nev}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">
                Nyilvántartási azonosító (kártyaszám)
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>{{selectedHorgasz.horgaszAzonosito}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">
                Cím
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>{{cim}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">
                Szül. hely és idő
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>{{szuletes}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">
                Anyja neve
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label *ngIf="selectedHorgasz.szemely && selectedHorgasz.szemely.anyjaNeve">
                {{selectedHorgasz.szemely.anyjaNeve}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">Állami jegy</ion-label>
            </ion-col>
            <ion-col class="linkcol">
              <a (click)="onDetails(horgaszDetailsPageType.ALLAMI_JEGY)" style="text-decoration: underline">
                További jegyek
              </a>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedHorgasz.ervenyesAllamiJegy">
            <ion-col>
              <div class="ion-text-uppercase">
                  <div *ngIf="selectedHorgasz.ervenyesAllamiJegy.statusz === allamiJegyStatuszEnum.ERVENYES">
                    <ion-label color="success">
                      {{selectedHorgasz.ervenyesAllamiJegy.statusz | allamiJegyStatusz}}
                      <span style="color: #f04141">{{selectedHorgasz.hanyadikErvenyesAllamiJegy}}.</span>
                    </ion-label>
                  </div>
                  <ion-label color="danger" *ngIf="selectedHorgasz.ervenyesAllamiJegy.statusz !== allamiJegyStatuszEnum.ERVENYES">
                    {{selectedHorgasz.ervenyesAllamiJegy.statusz | allamiJegyStatusz}}
                  </ion-label>
              </div>
            </ion-col>
            <ion-col>
              <ion-label>
                {{selectedHorgasz.ervenyesAllamiJegy.sorszam}}
              </ion-label>
            </ion-col>
            <ion-col>
              <ion-label>
                {{selectedHorgasz.ervenyesAllamiJegy.ervenyessegDatuma}}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!selectedHorgasz.ervenyesAllamiJegy">
            <ion-col>
              <ion-label color="danger">NINCS</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">Fogási napló</ion-label>
            </ion-col>
            <ion-col class="linkcol">
              <a (click)="onDetails(horgaszDetailsPageType.FOGASI_NAPLO)" style="text-decoration: underline">
                További naplók
              </a>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedHorgasz.ervenyesFogasiNaplo">
            <ion-col>
              <div class="ion-text-uppercase">
                <div *ngIf="selectedHorgasz.ervenyesFogasiNaplo.statusz === fogasiNaploStatuszEnum.KIADOTT">
                  <ion-label color="success">
                    {{selectedHorgasz.ervenyesFogasiNaplo.statusz | fogasiNaploStatusz}}
                    <span style="color: #f04141">{{selectedHorgasz.hanyadikErvenyesFogasiNaplo}}.</span>
                  </ion-label>
                </div>
                <ion-label color="danger" *ngIf="selectedHorgasz.ervenyesFogasiNaplo.statusz !== fogasiNaploStatuszEnum.KIADOTT">
                  {{selectedHorgasz.ervenyesFogasiNaplo.statusz | fogasiNaploStatusz}}
                </ion-label>
              </div>
            </ion-col>
            <ion-col>
              <ion-label>
                {{selectedHorgasz.ervenyesFogasiNaplo.sorszam}}
              </ion-label>
            </ion-col>
            <ion-col>
              <ion-label>
                {{selectedHorgasz.ervenyesFogasiNaplo.ervenyessegDatuma}}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!selectedHorgasz.ervenyesFogasiNaplo">
            <ion-col>
              <ion-label color="danger">NINCS</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item>
        <ion-grid  class="teruletijegygrid">
          <ion-row>
            <ion-col>
              <ion-label class="label">Területi és kiegészítő jegyek</ion-label>
            </ion-col>
            <ion-col class="linkcol">
              <a (click)="onDetails(horgaszDetailsPageType.TERULETI_JEGY)" style="text-decoration: underline">Részletek és további jegyek</a>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedHorgasz.ervenyesTeruletiJegy">
            <ion-col>
              <div class="ion-text-uppercase">
                <ion-label class="teruletijegynev"
                  [color]="teruletiJegyHelperService.getColor(selectedHorgasz.ervenyesTeruletiJegy)"
                  *ngIf="selectedHorgasz.ervenyesTeruletiJegy.statusz === allamiJegyStatuszEnum.ERVENYES">
                  {{selectedHorgasz.ervenyesTeruletiJegy.jogcim | teruletiJegy}}
                  {{selectedHorgasz.ervenyesTeruletiJegy.jogcimCsoport | teruletiJegy}}
                  {{selectedHorgasz.ervenyesTeruletiJegy.idoszak | teruletiJegy}}</ion-label>
                <ion-label [color]="teruletiJegyHelperService.getColor(selectedHorgasz.ervenyesTeruletiJegy)"
                           *ngIf="selectedHorgasz.ervenyesTeruletiJegy.statusz !== allamiJegyStatuszEnum.ERVENYES">
                  {{selectedHorgasz.ervenyesTeruletiJegy.statusz | teruletiJegyStatusz}}
                </ion-label>
              </div>
            </ion-col>
            <ion-col>
              <ion-label>
                {{selectedHorgasz.ervenyesTeruletiJegy.sorszam}} ({{selectedHorgasz.ervenyesTeruletiJegy.botokSzama}} bot)
              </ion-label>
            </ion-col>
          </ion-row>
          <div *ngIf="selectedHorgasz.ervenyesTeruletiJegy">
            <div *ngFor="let teruletiKiegeszitoJegy of selectedHorgasz.ervenyesTeruletiJegy.teruletiKiegeszitoJegyList">
              <ion-row *ngIf="isKiegeszitoErvenyes(teruletiKiegeszitoJegy)">
                <ion-col>
                  <div class="ion-text-uppercase">
                    <ion-label [color]="teruletiJegyHelperService.getKiegeszitoColor(teruletiKiegeszitoJegy)">
                      {{teruletiKiegeszitoJegy.statusz | teruletiJegyStatusz}} - {{teruletiKiegeszitoJegy.tipus | kiegeszitojegytipus}}
                    </ion-label>
                  </div>
                </ion-col>
                <ion-col>
                  <ion-label>
                    {{teruletiKiegeszitoJegy.sorszam}}
                  </ion-label>
                </ion-col>
              </ion-row>
            </div>
          </div>
          <ion-row *ngIf="!selectedHorgasz.ervenyesTeruletiJegy">
            <ion-col>
              <ion-label color="danger">NINCS</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-label class="label">Versenyzői engedély</ion-label>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="selectedHorgasz.ervenyesVersenyzoiEngedely">
                <ion-col>
                  <div class="ion-text-uppercase">
                    <ion-label color="success">{{selectedHorgasz.ervenyesVersenyzoiEngedely.statusz | versenyzoiengedelystatusz}}</ion-label>
                  </div>
                </ion-col>
                <ion-col>
                  <ion-text>
                    {{selectedHorgasz.ervenyesVersenyzoiEngedely.ervenyessegVege}}
                  </ion-text>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="!selectedHorgasz.ervenyesVersenyzoiEngedely">
              <ion-col >
                <ion-label color="danger">NINCS</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">Jogszabályi eltiltás</ion-label>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedHorgasz.szemelyEltiltas">
            <ion-col>
              <ion-text color="danger" *ngIf="selectedHorgasz.szemelyEltiltas.eloAzEltiltas">
                {{selectedHorgasz.szemelyEltiltas.eltiltasKezdete}} - {{selectedHorgasz.szemelyEltiltas.eltiltasVege}}
              </ion-text>
              <ng-container [ngSwitch]="selectedHorgasz.szemelyEltiltas.bevaneFizetve">
                <ion-text *ngSwitchCase="true" color="danger">Büntetés be van fizetve: {{selectedHorgasz.szemelyEltiltas.befizetesDatuma}}</ion-text>
                <ion-text *ngSwitchCase="false" color="danger">Büntetés nincs befizetve</ion-text>
              </ng-container>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!selectedHorgasz.szemelyEltiltas">
            <ion-col>
              <ion-label color="success">NINCS</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="label">Területi eltiltások</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col *ngIf="selectedHorgasz.szemelyTeruletiEltiltas" >
              <ion-text color="danger">
                {{selectedHorgasz.szemelyTeruletiEltiltas.vizteruletNev}}: {{selectedHorgasz.szemelyTeruletiEltiltas.eltiltasKezdete}} - {{selectedHorgasz.szemelyTeruletiEltiltas.eltiltasVege}}
              </ion-text>
            </ion-col>
            <ion-col *ngIf="!selectedHorgasz.szemelyTeruletiEltiltas">
              <ion-label color="success">NINCS</ion-label>
            </ion-col>
            <ion-col class="linkcol">
              <a (click)="onDetails(horgaszDetailsPageType.ELTILTAS)" style="text-decoration: underline">További területi eltiltások</a>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
<ion-footer *ngIf="!minositett">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button color="danger" (click)="szabalysertesClick()" expand="block" margin>Szabálysértés</ion-button>
        </ion-col>
        <ion-col>
          <ion-button color="primary" (click)="rendbenClick()" expand="block" margin>Rendben</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
