import {Pipe, PipeTransform} from '@angular/core';
import {TeruletiJegy} from 'src/api';

@Pipe({
    name: 'teruletiJegyStatusz',
    standalone: false
})
export class TeruletiJegyStatuszEnumPipe implements PipeTransform {
  transform(value: TeruletiJegy.StatuszEnum, args?: any): any {
    switch (value) {
      case TeruletiJegy.StatuszEnum.ERVENYES: return 'Érvényes'
      case TeruletiJegy.StatuszEnum.LEJART: return 'Lejárt'
      case TeruletiJegy.StatuszEnum.VISSZAVONT: return 'Visszavont'
      case TeruletiJegy.StatuszEnum.MEGVASAROLT: return 'Megvásárolt'
      case TeruletiJegy.StatuszEnum.SZTORNOZOTT: return 'Sztornózott'
      case TeruletiJegy.StatuszEnum.RKSZTORNOZOTT: return 'Érvénytelenített'
      default: return value;
    }
  }
}
