<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="type === HorgaszDetailsPageType.ALLAMI_JEGY">Állami jegyek</ion-title>
    <ion-title *ngIf="type === HorgaszDetailsPageType.FOGASI_NAPLO">Fogási naplók</ion-title>
    <ion-title *ngIf="type === HorgaszDetailsPageType.TERULETI_JEGY">Területi jegyek</ion-title>
    <ion-title *ngIf="type === HorgaszDetailsPageType.ELTILTAS">Területi eltiltások</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="type === HorgaszDetailsPageType.ALLAMI_JEGY && vanTovabbiAllamiJegy();  else fogasiNaplo">
    <div *ngFor="let allamijegy of horgasz.allamiJegyList">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle color="primary">
            {{allamijegy.sorszam}}
          </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-label color="primary">
            {{allamijegy.ervenyessegiEv}}, {{allamijegy.ervenyessegDatuma}}, <ion-text color="success"
              *ngIf="allamijegy.statusz === allamiJegyStatuszEnum.ERVENYES">
              {{allamijegy.statusz | allamiJegyStatusz}}</ion-text>
            <ion-text color="danger" *ngIf="allamijegy.statusz !== allamiJegyStatuszEnum.ERVENYES">
              {{allamijegy.statusz | allamiJegyStatusz}}</ion-text>
          </ion-label>
        </ion-card-content>
      </ion-card>
    </div>
  </ng-container>


  <ng-template #fogasiNaplo>
    <ng-container *ngIf="type === HorgaszDetailsPageType.FOGASI_NAPLO && vanTovabbiFogasiNaplo();  else teruletiJegy">
      <div *ngFor="let fogasiNaplo of horgasz.fogasiNaploList">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle color="primary">
              {{fogasiNaplo.sorszam}}
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-label color="primary">
              {{fogasiNaplo.ervenyessegiEv}}, {{fogasiNaplo.ervenyessegDatuma}},
              <ion-text color="success" *ngIf="fogasiNaplo.statusz === fogasiNaploStatuszEnum.KIADOTT">
                {{fogasiNaplo.statusz | fogasiNaploStatusz}}
              </ion-text>
              <ion-text color="danger" *ngIf="fogasiNaplo.statusz !== fogasiNaploStatuszEnum.KIADOTT">
                {{fogasiNaplo.statusz | fogasiNaploStatusz}}</ion-text>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #teruletiJegy>
    <ng-container *ngIf="type === HorgaszDetailsPageType.TERULETI_JEGY && vanTovabbiTeruletiJegy(); else eltiltas">
      <div *ngFor="let tj of horgasz.teruletiJegyList">
        <ion-card  class="teruleti-jegy">
          <ion-card-header>
            <ion-card-subtitle color="primary">
              {{tj.teruletiJegyTermekNev}}
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-label color="primary">
              {{tj.sorszam}} ({{tj.botokSzama}} bot),
              {{tj.ervenyessegKezdete | date:'yyyy-MM-dd HH:mm'}} - {{tj.ervenyessegVege | date:'yyyy-MM-dd HH:mm'}}
              <ion-text [color]="teruletiJegyHelperService.getColor(tj)">
                {{tj.statusz | teruletiJegyStatusz}}
              </ion-text>
            </ion-label>
          </ion-card-content>
        </ion-card>
        <div *ngFor="let tkj of tj.teruletiKiegeszitoJegyList">
          <ion-card  class="kiegeszito-jegy">
            <ion-card-header>
              <ion-card-subtitle color="primary">
                {{tkj.idoszak | kiegeszitojegyidoszak}} {{tkj.tipus | kiegeszitojegytipus | lowercase}}
                <ng-container *ngIf="tkj.vizterulet"> - {{tkj.vizterulet.nev}}</ng-container>
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-label color="primary">
                {{tkj.sorszam}},
                {{tkj.ervenyessegKezdete | date:'yyyy-MM-dd HH:mm'}} - {{tkj.ervenyessegVege | date:'yyyy-MM-dd HH:mm'}}
                <ion-text [color]="teruletiJegyHelperService.getKiegeszitoColor(tkj)">
                  {{tkj.statusz | teruletiJegyStatusz}}
                </ion-text>
              </ion-label>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #eltiltas>
    <ng-container *ngIf="type === HorgaszDetailsPageType.ELTILTAS && vanTovabbiTeruletiEltiltas(); else nincsTartalom">
      <div *ngFor="let szemelyTeruletiEltiltas of horgasz.szemelyTeruletiEltiltasList">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{szemelyTeruletiEltiltas.vizteruletNev}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-text *ngIf="szemelyTeruletiEltiltas.aktiv" color="danger">Aktív: </ion-text>
            <ion-text *ngIf="!szemelyTeruletiEltiltas.aktiv" color="primary">Nem aktív: </ion-text>
            <ion-text [color]="szemelyTeruletiEltiltas.aktiv ? 'danger': 'primary'">
              {{szemelyTeruletiEltiltas.eltiltasKezdete}} - {{szemelyTeruletiEltiltas.eltiltasVege}}
            </ion-text>
          </ion-card-content>
        </ion-card>
      </div>
    </ng-container>
  </ng-template>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button color="medium" (click)="cancel()" expand="block" class="margin">Vissza</ion-button>
  </ion-toolbar>
</ion-footer>

<ng-template #nincsTartalom>
  <ion-card>
    <ion-card-header>
      <ion-card-subtitle color="primary">
        <span *ngIf="type === HorgaszDetailsPageType.TERULETI_JEGY">Nincs további területi jegye</span>
        <span *ngIf="type === HorgaszDetailsPageType.ALLAMI_JEGY">Nincs további állami jegye</span>
        <span *ngIf="type === HorgaszDetailsPageType.ELTILTAS">Nincs további területi eltiltása</span>
      </ion-card-subtitle>
    </ion-card-header>
  </ion-card>
</ng-template>
