import { Pipe, PipeTransform } from '@angular/core';
import { FogasiNaplo } from 'src/api';

@Pipe({
    name: 'fogasiNaploStatusz',
    standalone: false
})
export class FogasiNaploStatuszPipe implements PipeTransform {

  transform(value: FogasiNaplo.StatuszEnum): unknown {
    switch (value) {
      case FogasiNaplo.StatuszEnum.BETELT: return 'Betelt'
      case FogasiNaplo.StatuszEnum.MEGSEMMISULT: return 'Megsemmisült'
      case FogasiNaplo.StatuszEnum.KIADOTT: return 'Kiadott'
      case FogasiNaplo.StatuszEnum.LEADOTT: return 'Leadott'
      case FogasiNaplo.StatuszEnum.VEGLEGESENSTORNOZOTT: return 'Véglegesen sztornózott'
      case FogasiNaplo.StatuszEnum.KIADOTTELVESZETTJOVAHAGYASRAVAR: return 'Elveszett, jóváhagyásra vár'
      case FogasiNaplo.StatuszEnum.KIADOTTMEGSEMMISULTJOVAHAGYASRAVAR: return 'Megsemmisült, jóváhagyásra vár'
      case FogasiNaplo.StatuszEnum.KIADOTTSZTORNOJOVAHAGYASRAVAR: return 'Sztornózott, jóváhagyásra vár'
      case FogasiNaplo.StatuszEnum.ELVESZETT: return 'Elveszett'
      case FogasiNaplo.StatuszEnum.VISSZAVONT: return 'Visszavont'
      case FogasiNaplo.StatuszEnum.SZTORNOZOTT: return 'Sztornózott'
      case FogasiNaplo.StatuszEnum.INAKTIV: return 'Inaktív'
      case FogasiNaplo.StatuszEnum.VISSZATARTOTT: return 'Visszatartott'
      default: return value;
    }
  }

}
