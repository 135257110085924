import {Injectable} from '@angular/core';
import { EllenorAppControllerService, HorgaszAdatReply, VizteruletHorgaszaiRequest } from 'src/api';

@Injectable({
  providedIn: 'root'
})
export class OfflineDataService {

  public uniqueIdToHorgaszAzonositoMap: Map<string, string>;
  private horgaszAzonositoToHorgaszMap: Map<string, HorgaszAdatReply>;
  private vizteruletHorgaszaiRequest: VizteruletHorgaszaiRequest;

  constructor(
    private ellenorAppControllerService: EllenorAppControllerService
  ) {
  }

  async refreshDataFromServer() {
    if (this.vizteruletHorgaszaiRequest) {
      let vizteruletHorgaszaiList: Array<HorgaszAdatReply> = [];
      let pageIndex = 0
      try {
        while (pageIndex !== undefined){
          this.vizteruletHorgaszaiRequest.pageIndex = pageIndex
          let blockContent = await this.ellenorAppControllerService.vizteruletHorgaszai(this.vizteruletHorgaszaiRequest).toPromise()
          if(blockContent.length > 0) {
            pageIndex++;
            vizteruletHorgaszaiList  = vizteruletHorgaszaiList.concat(blockContent);
          } else {
            pageIndex = undefined
          }
        }
      } catch (error) {
        console.log(error)
      }
      console.log("Vízterület horgász darabszám: " + vizteruletHorgaszaiList.length);
      this.mapResult(vizteruletHorgaszaiList)
    }
  }

  async initDataFromServer(request: VizteruletHorgaszaiRequest) {
    this.vizteruletHorgaszaiRequest = request;
    this.refreshDataFromServer();
  }

  private mapResult(result: Array<HorgaszAdatReply>) {
    this.uniqueIdToHorgaszAzonositoMap = new Map();
    this.horgaszAzonositoToHorgaszMap = new Map();
    result.forEach(horgaszAdatReply => {
      horgaszAdatReply.uniqueIds.forEach(uniquid => {
        this.uniqueIdToHorgaszAzonositoMap.set(uniquid, horgaszAdatReply.horgaszAzonosito);
      });
      this.horgaszAzonositoToHorgaszMap.set(horgaszAdatReply.horgaszAzonosito, horgaszAdatReply);
    });
  }

  search(filter: string): HorgaszAdatReply {
    const normalizedFilter = OfflineDataService.normalizeFilter(filter);
    const horgaszAzonosito: string = this.uniqueIdToHorgaszAzonositoMap.get(normalizedFilter);
    return horgaszAzonosito ? this.horgaszAzonositoToHorgaszMap.get(horgaszAzonosito) : null;
  }

  private static normalizeFilter(value: string): string {
    const normalized: string = value && value.length === 0 ? value : value
      .replace(/[^a-zA-Záéíúóöőüű0-9]+/g, '')
      .replace(/\s/g, '')
      .trim()
      .toLowerCase();
    //remove accents
    return OfflineDataService.removeAccents(normalized);
  }

  private static removeAccents(text: string) {
    return text && text.length === 0 ? text : text.normalize('NFD').replace(/[^\w]/g, '');
  }
}
