/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletItem { 
    id?: number;
    nev?: string;
    nyilvantartas?: VizteruletItem.NyilvantartasEnum;
    vizterkod?: string;
}
export namespace VizteruletItem {
    export type NyilvantartasEnum = 'HORINFO' | 'NEM_NYILVANTARTOTT' | 'HALTERMELESI_LETESITMENY';
    export const NyilvantartasEnum = {
        HORINFO: 'HORINFO' as NyilvantartasEnum,
        NEMNYILVANTARTOTT: 'NEM_NYILVANTARTOTT' as NyilvantartasEnum,
        HALTERMELESILETESITMENY: 'HALTERMELESI_LETESITMENY' as NyilvantartasEnum
    };
}