import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EllenorzesiTevekenysegReplyRow } from 'src/api';
import { DatabaseEllenorzes } from 'src/app/objects/databaseEllenorzes';
import { SelectedEllenorzesService } from 'src/app/services/selected-ellenorzes.service';

@Component({
    selector: 'app-ellenorzes-list-item',
    templateUrl: './ellenorzes-list-item.component.html',
    styleUrls: ['./ellenorzes-list-item.component.scss'],
    standalone: false
})
export class EllenorzesListItem implements OnInit, OnChanges {

  MINOSITES = EllenorzesiTevekenysegReplyRow.MinositesEnum;

  @Input() ellenorzes: DatabaseEllenorzes;

  keresettEgyediAzonosito: string;
  kliensIdopont: Date;
  minosites: EllenorzesiTevekenysegReplyRow.MinositesEnum;
  ejegyInaktivalas: boolean;
  enaploInaktivalas: boolean;
  fogasiNaploVisszatartas: boolean;
  horgaszTalalat: boolean;
  horgaszAzonosito: string;
  vezeteknev: string;
  keresztnev: string;
  syncronized: boolean
  vizteruletId: number

  constructor(
    private router: Router,
    private selectedHorgaszService: SelectedEllenorzesService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.keresettEgyediAzonosito = this.ellenorzes.getKeresettAzonosito()
    if (this.ellenorzes?.data.ellenorzes) {
      this.kliensIdopont = this.ellenorzes.data.ellenorzes.kliensIdopont
      this.minosites = this.ellenorzes.data.ellenorzes.minosites
      this.ejegyInaktivalas = this.ellenorzes.data.ellenorzes.ejegyInaktivalas
      this.enaploInaktivalas = this.ellenorzes.data.ellenorzes.enaploInaktivalas
      this.fogasiNaploVisszatartas = this.ellenorzes.data.ellenorzes.fogasiNaploVisszatartas
      this.horgaszTalalat = this.ellenorzes.data.ellenorzes.horgaszTalalat
      this.horgaszAzonosito = this.ellenorzes.data.ellenorzes.horgaszAzonosito
      this.vezeteknev = this.ellenorzes.data.ellenorzes.vezeteknev
      this.keresztnev = this.ellenorzes.data.ellenorzes.keresztnev
      this.vizteruletId = this.ellenorzes.data.ellenorzes.vizteruletId
    }
     if (this.ellenorzes?.data.ellenorzesRequest?.horgaszAdatRequest){
      this.kliensIdopont = this.ellenorzes.data.ellenorzesRequest.horgaszAdatRequest?.kliensIdopont
      this.horgaszTalalat = !!this.ellenorzes.data.ellenorzesRequest.horgaszAdatReply
      this.horgaszAzonosito = this.ellenorzes.data.ellenorzesRequest.horgaszAdatReply?.horgaszAzonosito
      this.vezeteknev = this.ellenorzes.data.ellenorzesRequest.horgaszAdatReply?.szemely?.vezeteknev
      this.keresztnev = this.ellenorzes.data.ellenorzesRequest.horgaszAdatReply?.szemely?.keresztnev
    }
    if (this.ellenorzes?.data.ellenorzesRequest?.minositesRequest) {
      this.minosites = this.ellenorzes.data.ellenorzesRequest.minositesRequest?.ellenorzesiTevekenysegMinosites
      this.ejegyInaktivalas = this.ellenorzes.data.ellenorzesRequest.minositesRequest?.ejegyInaktivalas
      this.enaploInaktivalas = this.ellenorzes.data.ellenorzesRequest.minositesRequest?.enaploInaktivalas
      this.fogasiNaploVisszatartas = this.ellenorzes.data.ellenorzesRequest.minositesRequest?.fogasiNaploVisszatartas
    }
    this.syncronized = this.ellenorzes?.data.synchronized
  }

  async onEllenorzesClick() {
    this.selectedHorgaszService.set(this.ellenorzes);
    await this.router.navigate(['members', 'horgasz-adatok']);
  }

}
