import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HorgaszAdatokPage} from './horgasz-adatok.page';
import {MemberModule} from '../member.module';
import { SzabalysertesDialogComponent } from './szabalysertes-dialog/szabalysertes-dialog.component';
import { TeruletiJegyStatuszEnumPipe } from './teruleti-jegy-statusz.pipe';
import { HorgaszDetailsPage } from './horgasz-details/horgasz-details.page';

const routes: Routes = [
  {path: '', component: HorgaszAdatokPage},
  //{path: 'horgasz-details', loadChildren: () => HorgaszDetailsPageModule}
];

@NgModule({
  imports: [
    MemberModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    HorgaszAdatokPage,
    HorgaszDetailsPage,
    SzabalysertesDialogComponent,
    TeruletiJegyStatuszEnumPipe
  ]
})
export class HorgaszAdatokPageModule {
}
