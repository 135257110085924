import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CapacitorBarcodeScanner, CapacitorBarcodeScannerTypeHint } from '@capacitor/barcode-scanner';
import { AlertController } from '@ionic/angular';
import { EllenorAppControllerService, HorgaszAdatRequest } from 'src/api';
import { AuthenticatedUser } from 'src/app/objects/authenticatedUser';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabaseService } from 'src/app/services/database.service';
import { OfflineDataService } from 'src/app/services/offline-data.service';
import { Utils } from 'src/app/services/utils';
import { SelectedEllenorzesService } from 'src/app/services/selected-ellenorzes.service';
import { v4 as uuidv4 } from 'uuid';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-ellenorzes',
    templateUrl: './ellenorzes.page.html',
    styleUrls: ['./ellenorzes.page.scss'],
    standalone: false
})
export class EllenorzesPage implements OnInit {

  searchText = '';
  user: AuthenticatedUser = new AuthenticatedUser();
  loading = false;

  constructor(
    private databaseService: DatabaseService,
    public authenticationService: AuthenticationService,
    private alertCtrl: AlertController,
    private router: Router,
    private ellenorAppControllerService: EllenorAppControllerService,
    private offlineDataService: OfflineDataService,
    private selectedEllenorzesService: SelectedEllenorzesService
  ) {
    this.authenticationService.sssUser.subscribe(user => {
      if (user && user.trusteeId !== null) {
        this.user = user;
      }
    });
  }

  ngOnInit() {
    this.authenticationService.requestRepeat.subscribe(isRequestRepeat => {
      if (isRequestRepeat) {
        this.searchHorgasz(this.searchText);
      }
    });
  }

  editSelectVizter() {
    this.router.navigate(['members', 'vizteruletek']);
  }

  qrCodeScanner() {
    CapacitorBarcodeScanner.scanBarcode({hint: CapacitorBarcodeScannerTypeHint.QR_CODE}).then(barcodeData => {
      this.searchText = barcodeData.ScanResult;
      this.searchHorgasz(this.searchText);
    }).catch(err => {
      console.log('Error', err);
    });
  }

  onSearchHorgasz() {
    this.searchHorgasz(this.searchText);
  }

  onConnectionStateChange() {
    const currentValue = this.authenticationService.onlineStatus;
    this.authenticationService.onlineStatus.next(!currentValue);
  }

  private async searchHorgasz(searchedText: string) {
    this.loading = true;
    //lekerdezzuk az aktualis poziciot
    let gpsKoordinata = await this.authenticationService.getLocation();
    if (gpsKoordinata) {

      const horgaszSearchRequest: HorgaszAdatRequest = {
        uuid: uuidv4(),
        egyediAzonosito: searchedText,
        ellenorSzemelyId: this.user.trusteeId,
        szervezetId: this.user.entrustingId,
        vizteruletId: this.user.selectedVizterulet.id,
        kliensIdopont: Utils.timestamp(),
        szelessegiFok: gpsKoordinata.szelessegiFok,
        hosszusagiFok: gpsKoordinata.hosszusagiFok,
      };

      if(this.searchText){
        //online mukodes esetes
        if (this.authenticationService.isOnlineMukodes()) {
          try {
            const ellenorzes = await firstValueFrom(this.ellenorAppControllerService.ellenorzes(horgaszSearchRequest));
            const databaseEllenorzes = await this.databaseService.insertEllenorzes(ellenorzes);
            if (!ellenorzes.horgaszAdatReply?.szemely) {
              const alert = await this.alertCtrl.create({
                header: 'Nem található adat.',
                message: `A keresett feltételekkel: ${searchedText} nem található adat.`,
                buttons: ['OK']
              });
              await alert.present();
            } else {
              //deprecated
              //const navigationExtras: NavigationExtras = { queryParams: { horgasz: JSON.stringify(horgasz) }};
              //await this.router.navigate(['members', 'horgasz-adatok'], navigationExtras);

              this.selectedEllenorzesService.set(databaseEllenorzes)
              await this.router.navigate(['members', 'horgasz-adatok']);
            }
          } catch (error) {
            if (error && !error.status) {
              //this.synchBodyDataService.onlineStatus.next(false);
              await this.searchHorgasz(searchedText);
            } else {
              const alert = await this.alertCtrl.create({
                header: 'Nem található adat.',
                message: `A keresett feltételekkel: ${searchedText} nem található adat.`,
                buttons: ['OK']
              });
              await alert.present();
            }
          }
        } else {
          const horgasz = this.offlineDataService.search(this.searchText);
          //mindig letrehozzuk az ellenorzest
          const databaseEllenorzes = await this.databaseService.insertEllenorzesRequest(horgaszSearchRequest, horgasz);
          if (!horgasz) {
            const alert = await this.alertCtrl.create({
              header: 'Nem található adat.',
              message: `A keresett feltételekkel: ${searchedText} nem található adat.`,
              buttons: ['OK']
            });
            await alert.present();
          } else {
            //deprecated
            //const navigationExtras: NavigationExtras = { queryParams: { horgasz: JSON.stringify(horgasz)}};
            //await this.router.navigate(['members', 'horgasz-adatok'], navigationExtras);

            this.selectedEllenorzesService.set(databaseEllenorzes)
            await this.router.navigate(['members', 'horgasz-adatok']);
          }
        }
      }
    } else {
      const alert = await this.alertCtrl.create({
        header: 'GPS elérés hiba',
        message: `A készülék nem tudta a GPS koordinátákat lekérdezni, ellenőrizze, hogy be van-e kapcsolva a helymeghatározás!`,
        buttons: ['OK']
      });
      await alert.present();
    }
    this.loading = false;
    this.searchText = '';
  }
}
