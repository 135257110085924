import {Component, Input, OnInit} from '@angular/core';
import { AllamiJegy, FogasiNaplo, HorgaszAdatReply } from 'src/api';
import {ModalController} from '@ionic/angular';
import {HorgaszDetailsPageType} from "../horgasz-details-page-type.enum";
import {TeruletiJegyHelperService} from "../teruleti-jegy-helper.service";

@Component({
    selector: 'app-horgasz-details',
    templateUrl: './horgasz-details.page.html',
    styleUrls: ['./horgasz-details.page.scss'],
    standalone: false
})
export class HorgaszDetailsPage implements OnInit {

  @Input() horgasz: HorgaszAdatReply = {};
  @Input() type: HorgaszDetailsPageType;
  allamiJegyStatuszEnum = AllamiJegy.StatuszEnum;
  fogasiNaploStatuszEnum = FogasiNaplo.StatuszEnum;

  HorgaszDetailsPageType = HorgaszDetailsPageType

  constructor(
    public teruletiJegyHelperService: TeruletiJegyHelperService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss();
  }

  vanTovabbiAllamiJegy(): boolean {
    return this.horgasz.allamiJegyList && this.horgasz.allamiJegyList.length > 0
  }
  vanTovabbiFogasiNaplo(): boolean {
    return this.horgasz.fogasiNaploList && this.horgasz.fogasiNaploList.length > 0
  }
  vanTovabbiTeruletiJegy(): boolean {
    return this.horgasz.teruletiJegyList && this.horgasz.teruletiJegyList.length > 0
  }
  vanTovabbiTeruletiEltiltas(): boolean {
    return this.horgasz.szemelyTeruletiEltiltasList && this.horgasz.szemelyTeruletiEltiltasList.length > 0
  }
}
