import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import { FogasiNaploStatuszPipe } from 'src/app/members/horgasz-adatok/fogasi-naplo-statusz.pipe';
import {AllamiJegyStatuszEnumPipe} from './horgasz-adatok/allami-jegy-statusz.pipe';
import {TeruletiJegyPipe} from '../pipes/arlista-teruleti.pipe';
import {KiegeszitoJegyTipusEnumPipe} from './horgasz-adatok/kiegeszito-jegy-tipus.pipe';
import {KiegeszitoJegyIdoszakEnumPipe} from './horgasz-adatok/kiegeszito-jegy-idoszak.pipe';
import {VersenyzoiEngedelyStatuszEnumPipe} from './horgasz-adatok/versenyzoi-engedely-statusz.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [
    AllamiJegyStatuszEnumPipe,
    FogasiNaploStatuszPipe,
    TeruletiJegyPipe,
    KiegeszitoJegyTipusEnumPipe,
    KiegeszitoJegyIdoszakEnumPipe,
    VersenyzoiEngedelyStatuszEnumPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AllamiJegyStatuszEnumPipe,
    FogasiNaploStatuszPipe,
    TeruletiJegyPipe,
    KiegeszitoJegyTipusEnumPipe,
    KiegeszitoJegyIdoszakEnumPipe,
    VersenyzoiEngedelyStatuszEnumPipe
  ],
})
export class MemberModule {
}
