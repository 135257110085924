import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { AllamiJegy, EllenorzesiTevekenysegMinositesRequest, FogasiNaplo, HorgaszAdatReply, TeruletiKiegeszitoJegy } from 'src/api';
import { AuthenticationService } from '../../services/authentication.service';
import { HorgaszDetailsPageType } from './horgasz-details-page-type.enum';
import { HorgaszDetailsPage } from './horgasz-details/horgasz-details.page';
import { TeruletiJegyHelperService } from './teruleti-jegy-helper.service';
import { DatabaseService } from 'src/app/services/database.service';
import { SelectedEllenorzesService } from 'src/app/services/selected-ellenorzes.service';
import { SzabalysertesDialogComponent } from './szabalysertes-dialog/szabalysertes-dialog.component';
import { SzabalysertesDialogData } from './szabalysertes-dialog/szabalysertes-dialog-data';

@Component({
    selector: 'app-horgasz-adatok',
    templateUrl: './horgasz-adatok.page.html',
    styleUrls: ['./horgasz-adatok.page.scss'],
    standalone: false
})
export class HorgaszAdatokPage implements OnInit {

  selectedHorgasz: HorgaszAdatReply;
  selectedVizteruletId: number;
  nev = '';
  cim = '';
  szuletes = '';
  allamiJegyStatuszEnum = AllamiJegy.StatuszEnum;
  fogasiNaploStatuszEnum = FogasiNaplo.StatuszEnum;
  minositett = false;

  horgaszDetailsPageType = HorgaszDetailsPageType

  constructor(
    public teruletiJegyHelperService: TeruletiJegyHelperService,
    private alertController: AlertController,
    private modalController: ModalController,
    private navCtrl: NavController,
    public authenticationService: AuthenticationService,
    private selectedEllenorzesService: SelectedEllenorzesService,
    private databaseService: DatabaseService
  ) {
    this.authenticationService.sssUser.subscribe(user => {
      if (user && user.trusteeId !== null) {
        this.selectedVizteruletId = user.selectedVizterulet.id;
      }
    });
  }

  ngOnInit() {
    this.selectedHorgasz = this.selectedEllenorzesService.getHorgaszAdat()
    if (this.selectedHorgasz.szemely) {
      this.nev = this.selectedHorgasz.szemely.vezeteknev + ' ' + this.selectedHorgasz.szemely.keresztnev;
      this.szuletes = this.selectedHorgasz.szemely.szuletesiHely + ', ' + this.selectedHorgasz.szemely.szuletesiDatum;
      const al = this.selectedHorgasz.szemely.allandoLakcim
      if(al){
        this.cim = `${al.iranyitoszam} ${al.telepules} ${al.kozteruletNev || ''} ${al.kozteruletJelleg || ''} ${al.hazszam || ''}`;
      }
    }
    this.minositett = this.selectedEllenorzesService.isMinositett()
  }

  async rendbenClick() {
    const alert = await this.alertController.create({
      header: 'Biztosan menti az ellenőrzés eredményét?',
      message: `Mentés után az ellenőrzés már nem módosítható`,
      backdropDismiss: false,
      buttons: [
        { text: 'Mentés', handler: () => this.minosites(EllenorzesiTevekenysegMinositesRequest.EllenorzesiTevekenysegMinositesEnum.RENDBEN) },
        { text: 'Mégsem' }]
    });
    await alert.present();
  }

  private async minosites(ellenorzesiTevekenysegMinosites: EllenorzesiTevekenysegMinositesRequest.EllenorzesiTevekenysegMinositesEnum) {
    const elReq: EllenorzesiTevekenysegMinositesRequest = {
      uuid: this.selectedEllenorzesService.get().getUuid(),
      ellenorzesiTevekenysegId: this.selectedHorgasz.ellenorzesiTevekenysegId,
      ellenorzesiTevekenysegMinosites: ellenorzesiTevekenysegMinosites
    };
    await this.databaseService.insertEllenorzesMinositesRequest(elReq);
    this.navCtrl.back();
  }

  async szabalysertesClick() {
    this.minositesSzabalysertes();
  }

  private async minositesSzabalysertes() {
    if (!this.selectedHorgasz.ervenyesAllamiJegy) {
      const alert = await this.alertController.create({
        header: 'Biztosan menti az ellenőrzés eredményét?',
        message: `Mentés után az ellenőrzés már nem módosítható`,
        backdropDismiss: false,
        buttons: [
          { text: 'Mentés', handler: () => this.minosites(EllenorzesiTevekenysegMinositesRequest.EllenorzesiTevekenysegMinositesEnum.SZABALYSERTES) },
          { text: 'Mégsem' }]
      });
      await alert.present();
    } else {
      const props: SzabalysertesDialogData = {
        ellenorzesiTevekenysegId: this.selectedHorgasz.ellenorzesiTevekenysegId,
        hasEnaplo: this.selectedHorgasz.ervenyesFogasiNaplo?.elektronikus
      }
      const modal = await this.modalController.create({
        component: SzabalysertesDialogComponent,
        componentProps: {
          data: props
        }
      })
      await modal.present();
    }
  }

  async onDetails(type: HorgaszDetailsPageType) {
    const modal = await this.modalController.create({
      component: HorgaszDetailsPage,
      componentProps: { horgasz: this.selectedHorgasz, type }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }

  isKiegeszitoErvenyes(tkj: TeruletiKiegeszitoJegy){
    return tkj.ervenyes && (tkj.vizterulet ? tkj.vizterulet.id === this.selectedVizteruletId : true)
  }
}
