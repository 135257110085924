<ion-app>
  <ion-menu type="overlay" contentId="main">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list *ngIf="loggedIn && !hasValasztottVizterulet">
        <ion-menu-toggle auto-hide="false">
          <ion-item *ngFor="let p of appLoggedInPages" [routerDirection]="'root'" [routerLink]="[p.url]"
            [class.active-item]="selectedPath === p.url">
            <!-- <ion-icon slot="start" [name]="p.icon"></ion-icon> -->
            <ion-label>
              {{p.title}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list *ngIf="loggedIn && hasValasztottVizterulet">
        <ion-menu-toggle auto-hide="false">
          <ion-item *ngFor="let p of appHasVizteruletPages" [routerDirection]="'root'" [routerLink]="[p.url]"
            [class.active-item]="selectedPath === p.url">
            <!-- <ion-icon slot="start" [name]="p.icon"></ion-icon> -->
            <ion-label>
              {{p.title}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list *ngIf="!loggedIn">
        <ion-menu-toggle auto-hide="false" *ngFor="let p of appLoggedOutPages"
          [class.active-item]="selectedPath === p.url">
          <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
            <!-- <ion-icon slot="start" [name]="p.icon"></ion-icon> -->
            <ion-label>
              {{p.title}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <ion-footer *ngIf="loggedIn">
      <ion-toolbar>
        <ion-item no-line *ngIf="addedValasztoMenu" (click)="navigateDashboard()"
          [class.active-item]="selectedPath === 'dashboard'">
          <!-- <ion-icon slot="start" [name]="p.icon"></ion-icon> -->
          <ion-label>
            Szervezetválasztó
          </ion-label>
        </ion-item>
<!--        <ion-button class="ion-margin-vertical" expand="block" (click)="offlineMukodes()" fill="solid" color="medium">-->
<!--          Offline működés-->
<!--        </ion-button>-->
<!--        <ion-button class="ion-margin-vertical" expand="block" (click)="onlineMukodes()" fill="solid" color="medium">-->
<!--          Online működés-->
<!--        </ion-button>-->
        <ion-button class="ion-margin-vertical" expand="block" (click)="logout()" fill="solid" color="medium">
          <!-- <ion-icon slot="start" name="log-out"></ion-icon> -->
          Kijelentkezés
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
